import axios from "axios";
import { baseDomain, deleteCalenderEvent, editCalenderEvent, getCalenderEventById } from "../../../../Constants/apiRoutes";
import {
  calenderEvent,
  createCalenderEvent,
} from "../../../../Constants/apiRoutes";
import { getLocal } from "../../../../Utils/localStorage";
import { formatDate } from "../../../../Utils/common";

export const getCalenderEventreq = () => {
  const token = getLocal("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const today = new Date();
  const startDate = today.setMonth(today.getMonth() - 1);
  const endDate = today.setFullYear(today.getFullYear() + 2);
  return axios.get(
    `${baseDomain}${calenderEvent}?start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}`,
    config
  );
};

export const createCalenderEventreq = (data) => {
  const token = getLocal("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  return axios.post(`${baseDomain}${createCalenderEvent}`, data, config);
};

export const deleteCalenderEventreq = (id,data) => {
  const token = getLocal("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  return axios.put(`${baseDomain}${deleteCalenderEvent}/${id}`,data, config);
};

export const updateCalenderEventreq = (eventId, data) => {
  const token = getLocal("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  return axios.put(`${baseDomain}${editCalenderEvent}/${eventId}`, data, config);
};

export const getCalenderEventByIdreq = (id) => {
  const token = getLocal("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  return axios.get(`${baseDomain}${getCalenderEventById}/${id}`, config);
};
