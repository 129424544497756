import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Form, Row, Col } from "reactstrap";
import { FaPen, FaWindowClose } from "react-icons/fa";

const PreviousExperience = ({ data, updateData }) => {
  const {
    reason_of_joining,
    has_previous_experience_as_dog_sitter,
    as_sitter_experience_details,
    has_formal_training,
    formal_training_details,
    cared_for_other_than_dog,
    caring_details_for_other_than_dog,
  } = data || {};
  const initialFormData = {
    reason_of_joining: reason_of_joining || "",
    has_previous_experience_as_dog_sitter: has_previous_experience_as_dog_sitter
      ? "Yes"
      : "No",
    as_sitter_experience_details: as_sitter_experience_details || "",
    has_formal_training: has_formal_training ? "Yes" : "No",
    formal_training_details: formal_training_details || "",
    cared_for_other_than_dog: cared_for_other_than_dog ? "Yes" : "No",
    caring_details_for_other_than_dog: caring_details_for_other_than_dog || "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [validationMessage, setValidationMessage] = useState("");
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (data) {
      setFormData(initialFormData);
    }
  }, [data]);

  useEffect(() => {
    if (!editMode) {
      setFormData(initialFormData);
    }
  }, [editMode]);

  const handleInput = (e) => {
    if (editMode) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    // Define an array to store validation errors
    const validationErrors = {};

    // Check if any of the required fields are empty
    if (!formData.reason_of_joining) {
      validationErrors["reason_of_joining"] =
        "Please provide a reason for joining.";
    }

    if (!formData.has_previous_experience_as_dog_sitter) {
      validationErrors["has_previous_experience_as_dog_sitter"] =
        "Please select if you have previous experience as a dog sitter for someone else.";
    }

    if (
      formData.has_previous_experience_as_dog_sitter === "Yes" &&
      !formData.as_sitter_experience_details
    ) {
      validationErrors["as_sitter_experience_details"] =
        "Please provide details about your previous experience as a dog sitter for someone else.";
    }

    if (!formData.has_formal_training) {
      validationErrors["has_formal_training"] =
        "Please select if you have any formal training related to dogs or other animals.";
    }

    if (
      formData.has_formal_training === "Yes" &&
      !formData.formal_training_details
    ) {
      validationErrors["formal_training_details"] =
        "Please provide details about your formal training related to dogs or other animals.";
    }

    if (!formData.cared_for_other_than_dog) {
      validationErrors["cared_for_other_than_dog"] =
        "Please select if you have cared for other types of animals besides dogs.";
    }

    if (
      formData.cared_for_other_than_dog === "Yes" &&
      !formData.caring_details_for_other_than_dog
    ) {
      validationErrors["caring_details_for_other_than_dog"] =
        "Please provide details about your experience caring for other types of animals.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidationMessage(validationErrors);
    } else {
      // If there are no validation errors, proceed with form submission
      const payload = {
        reason_of_joining: formData.reason_of_joining,
        has_previous_experience_as_dog_sitter:
          formData.has_previous_experience_as_dog_sitter === "Yes",
        as_sitter_experience_details: formData.as_sitter_experience_details,
        has_formal_training: formData.has_formal_training === "Yes",
        formal_training_details: formData.formal_training_details,
        cared_for_other_than_dog: formData.cared_for_other_than_dog === "Yes",
        caring_details_for_other_than_dog:
          formData.caring_details_for_other_than_dog,
      };
      await updateData(payload);
      setEditMode(false);
    }
  };

  return (
    <Form>
      <Row>
        <Col md="12">
          <div style={{ float: "right", marginRight: "10px" }}>
            {editMode ? (
              <FaWindowClose
                onClick={() => {
                  setEditMode(false);
                  setValidationMessage("");
                }}
              />
            ) : (
              <FaPen
                onClick={() => {
                  setEditMode(true);
                  setValidationMessage("");
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <FormGroup>
            <Label htmlFor="reason_of_joining">
              Why did you join our network?
            </Label>
            <Input
              name="reason_of_joining"
              placeholder="Enter Reason"
              value={formData.reason_of_joining}
              onChange={handleInput}
              type="textarea"
              errorMessage="reason_of_joining is required"
              className="form-control"
              disabled={!editMode}
              style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
            />
            {validationMessage.reason_of_joining && (
              <div style={{ color: "red" }}>
                {validationMessage.reason_of_joining}
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <FormGroup>
            <Label htmlFor="has_previous_experience_as_dog_sitter">
              Do you have previous experience as a dog sitter for someone else?
            </Label>
            <Input
              name="has_previous_experience_as_dog_sitter"
              placeholder="Enter Previous Experience"
              value={formData.has_previous_experience_as_dog_sitter}
              onChange={handleInput}
              type="select"
              errorMessage="has_previous_experience_as_dog_sitter is required"
              className="form-control"
              disabled={!editMode}
              style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              id="has_previous_experience_as_dog_sitter"
            >
              <option hidden>Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Input>
            {validationMessage.has_previous_experience_as_dog_sitter && (
              <div style={{ color: "red" }}>
                {validationMessage.has_previous_experience_as_dog_sitter}
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
      {formData.has_previous_experience_as_dog_sitter === "Yes" && (
          <Col md="12">
            <FormGroup>
              <Label htmlFor="as_sitter_experience_details">
                If YES, please tell us about this experience in detail
              </Label>
              <Input
                name="as_sitter_experience_details"
                placeholder="Enter Sitter Experience"
                value={formData.as_sitter_experience_details}
                onChange={handleInput}
                type="textarea"
                errorMessage="as_sitter_experience_details is required"
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="as_sitter_experience_details"
              />
              {validationMessage.as_sitter_experience_details && (
                <div style={{ color: "red" }}>
                  {validationMessage.as_sitter_experience_details}
                </div>
              )}
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        <Col md="12">
          <FormGroup>
            <Label htmlFor="has_formal_training">
              Do you have any formal training related to dogs or other animals?
            </Label>
            <Input
              name="has_formal_training"
              placeholder="Enter has_formal_training"
              value={formData.has_formal_training}
              onChange={handleInput}
              type="select"
              errorMessage="has_formal_training is required"
              className="form-control"
              disabled={!editMode}
              style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              id="has_formal_training"
            >
              <option hidden>Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Input>
            {validationMessage.has_formal_training && (
              <div style={{ color: "red" }}>
                {validationMessage.has_formal_training}
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
      {formData.has_formal_training === "Yes" && (
          <Col md="12">
            <FormGroup>
              <Label htmlFor="formal_training_details">
                If YES, please tell us about this training in detail
              </Label>
              <Input
                name="formal_training_details"
                placeholder="Enter Formal Training"
                value={formData.formal_training_details}
                onChange={handleInput}
                type="textarea"
                errorMessage="formal_training_details is required"
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="formal_training_details"
              />
              {validationMessage.formal_training_details && (
                <div style={{ color: "red" }}>
                  {validationMessage.formal_training_details}
                </div>
              )}
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        <Col md="12">
          <FormGroup>
            <Label htmlFor="cared_for_other_than_dog">
              Have you cared for other types of animals (besides dogs)?
            </Label>
            <Input
              name="cared_for_other_than_dog"
              placeholder="Enter cared_for_other_than_dog"
              value={formData.cared_for_other_than_dog}
              onChange={handleInput}
              type="select"
              errorMessage="cared_for_other_than_dog is required"
              className="form-control"
              disabled={!editMode}
              style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              id="cared_for_other_than_dog"
            >
              <option hidden>Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Input>
            {validationMessage.cared_for_other_than_dog && (
              <div style={{ color: "red" }}>
                {validationMessage.cared_for_other_than_dog}
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
      {formData.cared_for_other_than_dog === "Yes" && (
          <Col md="12">
            <FormGroup>
              <Label htmlFor="caring_details_for_other_than_dog">
                If YES, please tell us about this experience in detail
              </Label>
              <Input
                name="caring_details_for_other_than_dog"
                placeholder="Enter Caring Details"
                value={formData.caring_details_for_other_than_dog}
                onChange={handleInput}
                type="textarea"
                errorMessage="caring_details_for_other_than_dog is required"
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="caring_details_for_other_than_dog"
              />
              {validationMessage.caring_details_for_other_than_dog && (
                <div style={{ color: "red" }}>
                  {validationMessage.caring_details_for_other_than_dog}
                </div>
              )}
            </FormGroup>
          </Col>
        )}
      </Row>
      {editMode && (
        <Row>
          <Col md="6">
            <FormGroup>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
              >
                Update
              </button>
            </FormGroup>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default PreviousExperience;
