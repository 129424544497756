import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseDomain, loginAPI } from '../../Constants/apiRoutes'

// export const loginSitterAPI = (data) => {
//   return axios.post(`${baseDomain}${loginAPI}`, data).then((res) => {
//     if(res.data.response_code === 90){
//       toast.error(res.data.developer_message, {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: false,
//         progress: undefined,
//         });
//     }
//     if(res.data.response_code === 80){
//       const token = res.data.data.token
//       window.localStorage.setItem('token', token)
//     }
    
//   })
// }

// export const fetchUserDataAPI = () => {
//   return axios.get(`${baseDomain}${getMe}`)
// }

export const resetPassword = (data) => {
  return axios.post(`${baseDomain}/account/sitter/reset/password/send-link`,data
  )
}


export const confirmResetPassword = (data) => {
  if (data.admin){
    return axios.post(`${baseDomain}/administrator/reset/password`,data
    )
  }
  return axios.post(`${baseDomain}/account/sitter/reset/password`,data
  )
}
