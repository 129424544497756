import axios from "axios";
import { getLocal } from "../../../../Utils/localStorage";
import { baseDomain, getBookingData } from "../../../../Constants/apiRoutes";

export const getBookingInformation = (bookingId) => {
    const token = getLocal("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(`${baseDomain}${getBookingData}/${bookingId}`,config);
  };