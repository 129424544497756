const PrivacyAndTerms = () => {
  return (
    <div className="form-main p-4">
      <h1 className="Form-title text-center">Privacy Policy</h1>
      <p className="text-center">Effective 1 September 2022</p>
      <p className="text-center">
        This Privacy Statement explains how DogStays SARL-S and its affiliates
        (“DogStays,” “we,” or “us”) collect and use your personal information to
        provide our services to you. This Privacy Statement applies to personal
        information we collect when you use the DogStays Service or when you
        otherwise interact with us. This Privacy Statement does not apply to
        websites, applications, or services that display or link to other
        privacy statements. We may change this Privacy Statement from time to
        time. If we make changes, we will notify you by revising the date at the
        top of this page. If we make material changes, we will provide you with
        notice through the Services or through other means, such as email.
      </p>
      <div>
        <h5>Table of Contents</h5>
        <ul>
          <li>How Do We Collect and Use Your Information?</li>
          <li>How Do We Share Your Information?</li>
          <li>Information Security</li>
          <li>Information Retention</li>
          <li>Your Choices</li>
          <li>UK and European Economic Area Data Protection Rights</li>
          <li>Contact Us</li>
        </ul>
      </div>
      <div>
        <h5>How Do We Collect and Use Your Information?</h5>
        <p>
          The information we collect varies based on how You interact with us,
          which Services You use, and the choices You make. We collect and
          process information about You with your consent and/or as necessary to
          provide our services, operate our business, meet our contractual and
          legal obligations, protect the security of our systems and our
          Clients, or fulfill other legitimate interests. We collect information
          about You in various ways when You use our Services, including
          information you provide directly, information collected automatically,
          and information collected via third-party data sources.When you are
          asked to provide information, you may decline. But if you choose not
          to provide information that is necessary our services may not be
          available to You.
        </p>
      </div>

      <div>
        <table className="table-bordered w-100">
          <thead>
            <tr>
              <th className="w-50 ps-2">Category Of Personal Data</th>
              <th className="w-50 ps-2">Processing Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td colspan="2">
                Information You Provide to Us: We collect information you
                provide directly to us, as follows:
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Account Information</h5>
                <p>
                  includes personal information we collect when you create an
                  account and list the Services through DogStays. Account
                  information includes your name, email address, postal address,
                  phone number, date of birth, pet information, availability,
                  and any other account-related information you choose to
                  provide.
                </p>
              </td>
              <td className="p-3">
                <h5>We use this information to</h5>
                <ul>
                  <li>
                    create and register your account on the DogStays Service;
                  </li>
                  <li>provide, maintain and improve our Services;</li>
                  <li>
                    enable communications between you and other users of the
                    Services;
                  </li>
                  <li>
                    send you technical notices, updates, security alerts and
                    support and administrative messages;
                  </li>
                  <li>present you with content that is relevant to you;</li>
                  <li>
                    communicate with you about products, services, offers,
                    promotions, rewards, and events offered by DogStays and
                    others, and provide news and information we think will be of
                    interest to you.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Account Information</h5>
                <p>
                  includes personal information we collect when you create an
                  account and list the Services through DogStays. Account
                  information includes your name, email address, postal address,
                  phone number, date of birth, pet information, availability,
                  and any other account-related information you choose to
                  provide.
                </p>
              </td>
              <td className="p-3">
                <h5>We use this information to</h5>
                <ul>
                  <li>
                    create and register your account on the DogStays Service;
                  </li>
                  <li>provide, maintain and improve our Services;</li>
                  <li>
                    enable communications between you and other users of the
                    Services;
                  </li>
                  <li>
                    detect, investigate and prevent fraudulent transactions and
                    other illegal or unauthorized activities and protect the
                    rights and property of DogStays and others.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Identification Information</h5>
                <p>
                  If you are a Service Provider, we may collect certain personal
                  information from you for purposes of verifying your identity
                  or assisting us in creating an account for you in our system:
                </p>
                <ul>
                  <li>
                    In Luxembourg, we request for a copy of a valid
                    identification document (like your passport or EU ID) and an
                    address proof like your certificate of residence in
                    Luxembourg or a similar government document if you live
                    outside Luxembourg.
                  </li>
                </ul>
              </td>
              <td className="p-3">
                <h5>We use this information to</h5>
                <ul>
                  <li>
                    conduct Identity Verification prior to you listing Services
                    you wish to offer via DogStays;
                  </li>
                  <li>
                    detect, investigate and prevent fraudulent transactions and
                    other illegal or unauthorized activities and protect the
                    rights and property of DogStays and others.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Other Information</h5>
                <p>
                  includes all other personal information you provide to us,
                  such as when you submit a job application, participate in a
                  promotion, complete a survey, communicate with us via third
                  party social media sites, request customer support, provide us
                  with contact information for a referral, or otherwise
                  communicate with us.{" "}
                </p>
              </td>
              <td className="p-3">
                <h5>We use this information to</h5>
                <ul>
                  <li>
                    respond to your comments, questions and requests and provide
                    customer service;
                  </li>
                  <li>
                    communicate with you about products, services, offers,
                    promotions, rewards, and events offered by DogStays and
                    others, and provide news and information we think will be of
                    interest to you;
                  </li>
                  <li>
                    facilitate contests, sweepstakes and promotions and process
                    and deliver entries and rewards;
                  </li>
                  <li>fulfill your referral requests;</li>
                  <li>
                    carry out any other purpose described to you at the time the
                    information is collected.
                  </li>
                </ul>
              </td>
            </tr>
            <tr className="text-center">
              <td colspan="2">
                Information Collected Automatically When You Use the Services:
                When you access or use the Services, we may automatically
                collect information about you, as follows:
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Usage Information</h5>
                <p>
                  We might collect/log information about your use of the
                  Services, including the type of browser you use, access times,
                  pages viewed, your IP address and the page you visited before
                  navigating to our Services, and information regarding your
                  interactions with our sites and applications.
                </p>{" "}
              </td>
              <td className="p-3">
                <h5>We use this information to</h5>
                <ul>
                  <li>present the Services to you on your device;</li>
                  <li>
                    select a language and region in which to present the
                    Services to you
                  </li>
                  <li>provide, maintain and improve our Services; </li>
                  <li>
                    send you technical notices, updates, security alerts and
                    support and administrative messages
                  </li>
                  <li>
                    detect, investigate and prevent fraudulent transactions and
                    other illegal or unauthorized activities and protect the
                    rights and property of DogStays and others.
                  </li>
                  <li>
                    communicate with you about products, services, offers,
                    promotions, rewards, and events offered by DogStays and
                    others, and provide news and information we think will be of
                    interest to you;
                  </li>
                  <li>
                    link or combine with information we get from other sources
                    to help understand your needs and provide you with better
                    service.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Device Information</h5>
                <p>
                  We might collect information about the computer or mobile
                  device you use to access our Services, including the hardware
                  model, operating system and version, unique device
                  identifiers, mobile network information, and whether
                  notification and location permissions are enabled.
                </p>
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Location Information</h5>
                <p>
                  We may collect information about the location of your device
                  each time you access or use certain features or otherwise
                  consent to the collection of this information. For more
                  details, please see “Your Choices” below.{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Cookie Information</h5>
                <p>
                  We use various technologies to operate our Services and
                  collect information, including cookies and web beacons
                  (collectively, “Cookies”). Most browsers are set to accept
                  cookies. To learn more about how we use Cookies and how to
                  manage your preferences, please read our Cookie Policy.{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Inferences</h5>
                <p>
                  We infer new information from other information we collect,
                  including using automated means to generate information about
                  your likely preferences or other characteristics. For example,
                  we infer your city, state, and country location based on your
                  IP address.{" "}
                </p>
              </td>
            </tr>
            <tr className="text-center">
              <td colspan="2">
                Information We Collect from Third Parties: We may collect
                information about you from third parties, as follows:
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Social Media Information</h5>
                <p>
                  If you create or log into your account through a social media
                  site, we will have access to certain information from that
                  site, such as your name, account information and friends
                  lists, in accordance with the authorization procedures
                  determined by such social media site.
                </p>
              </td>
              <td className="p-3">
                <h5>We use this information to</h5>
                <ul>
                  <li>
                    allow you to log in to the DogStays Service and to help
                    create a public profile for you;
                  </li>
                  <li>
                    allow you to share certain content related to your use of
                    the Services with your friends, followers or contacts on
                    that social media site.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>Other Third-Party Information</h5>
                <p>
                  includes information we receive (sometimes for a fee) from
                  other sources, including partners with whom we offer
                  co-branded services or engage in joint marketing activities,
                  agencies that conduct background check or identity
                  verification services, and publicly available sources.{" "}
                </p>
              </td>
              <td className="p-3">
                <p>
                  We may use this information on its own or in combination with
                  information you provide to us directly to contact you, send
                  you promotional materials, personalize our Service,
                  pre-populate online forms, and better understand the
                  demographics of our users.
                </p>
              </td>
            </tr>
            <tr>
              <td className="p-3">
                <h5>All Information Set Forth Above</h5>
              </td>
              <td className="p-3">
                <h5>We use this information to</h5>
                <ul>
                  <li>provide, maintain and improve our Services;</li>
                  <li>
                    detect, investigate and prevent fraudulent transactions and
                    other illegal or unauthorized activities and protect the
                    rights and property of DogStays and others;
                  </li>
                  <li>
                    defend ourselves from potential litigation or legal claims;
                  </li>
                  <li>comply with any applicable legal obligations; </li>
                  <li>
                    carry out any other purpose described to you at the time the
                    information is collected.
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-4">
        <h5>How Do We Share Your Information?</h5>
        <p>
          We may share the personal information we collect with third parties as
          follows or as otherwise described in this Privacy Policy:
        </p>
        <ul>
          <li>when you accept a Booking</li>
          <li>
            with vendors, consultants, business partners, and other service
            providers who need access to such information to carry out work on
            our behalf
          </li>
          <li>
            in response to a request for information if we believe disclosure is
            in accordance with, or required by, applicable law, regulation or
            legal process
          </li>
          <li>
            if we believe your actions are inconsistent with our user agreements
            or policies, or to protect the rights, property or safety of
            DogStays, its employees, its users (including their pets), or the
            public
          </li>
          <li>
            in connection with, or during negotiations of, any merger, sale of
            company assets, financing, acquisition, divestiture, bankruptcy,
            dissolution, or other transaction or proceeding involving sale,
            transfer or divestiture of all or a portion of our business or
            assets to another company, to the extent permitted under applicable
            law
          </li>
          <li>
            between and among DogStays and our current and future parents,
            affiliates, subsidiaries and other companies under common control
            and ownership
          </li>
          <li>
            on an aggregated and anonymized basis and with your consent or at
            your direction.
          </li>
        </ul>
      </div>

      <div>
        <h5>Advertising and Analytics Services Provided by Third Parties</h5>
        <p>
          We may allow others to provide analytics services and/or serve
          advertisements on our behalf across the Internet. These entities may
          use cookies, pixel tags, web beacons or other similar technologies to
          collect information about your use of our websites and third-party
          websites. Note that if you opt-out of third-party advertising cookies,
          you will still see online ads, but they will not be personalized.{" "}
        </p>
      </div>

      <div>
        <h5>Information Security</h5>
        <p>
          DogStays takes reasonable measures designed to help protect
          information about you from loss, theft, misuse and unauthorized
          access, use, disclosure, alteration and destruction. Please be aware
          that no method of transmission over the Internet or through mobile
          applications is 100% safe. Therefore, although we use commercially
          reasonable means to protect your information, we cannot guarantee its
          absolute security.
        </p>
      </div>

      <div>
        <h5>Transfer and Location of Information</h5>
        <p>
          The information we collect may be stored and processed in your region,
          or in any other country where we or our affiliates, subsidiaries, or
          service providers maintain facilities. The processing locations are
          chosen in order to operate efficiently, to improve performance, and to
          create redundancies in order to protect information in the event of an
          outage or other problem. We take steps designed to ensure that the
          information we collect under this statement is processed according to
          the provisions of this statement and applicable law wherever the
          information is located.{" "}
        </p>
      </div>

      <div>
        <h5>Information Retention</h5>
        <p>
          We retain information for as long as necessary to provide the products
          and fulfill the transactions you have requested, or for other
          essential purposes such as complying with our legal obligations,
          resolving disputes, and enforcing our agreements. Because these needs
          can vary for different information types in the context of different
          products, actual retention periods can vary based on criteria such as
          user expectations or consent, the sensitivity of the information, the
          availability of automated controls that enable users to delete
          information, and our legal or contractual obligations.{" "}
        </p>
      </div>

      <div>
        <h1 className="Form-title text-center mt-5">Your Choices</h1>
        <div>
          <h5>Managing Your Account</h5>
          <p>
            You may update, correct or delete the information provided to us
            about You at any time by contacting us using the contact information
            below.
          </p>
          <p>
            If you wish to leave the DogStays Sitter Network please contact us
            using the contact information below. We may decline requests that
            are prohibited by law, could adversely affect the privacy or other
            rights of another person, are not required to be honored by
            applicable law, or where we are unable to authenticate you as the
            person to whom the information relates.{" "}
          </p>
        </div>

        <div>
          <h5>Location Information</h5>
          <p>
            Whenever you use our service you might be asked to consent to the
            collection of location information. We sometimes use location
            information for certain features of our Services, so if You do not
            consent to this collection, those features might not work for you
          </p>
        </div>

        <div>
          <h5>Emails</h5>
          <p>
            You may choose whether you wish to receive marketing emails from
            DogStays. If you wish to opt-out, you may also do so by clicking
            “Unsubscribe” in these emails. When you opt out of receiving
            marketing emails you will still receive Service-related emails, such
            as those about your account, Bookings, Meetings or your ongoing
            business relationship with us.{" "}
          </p>
        </div>

        <div>
          <h5>Text Messages</h5>
          <p>
            With your consent as required by applicable law, we may send
            marketing and/or service-related text messages to your mobile
            device. You may opt out of receiving these messages by following the
            prompt in an individual message. However, if you are a Dog Sitter in
            our network, certain Services that you provide may require you to
            receive service-related messages. If you do not want to receive
            these service-related text messages, then you must withdraw from our
            Sitter Network.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyAndTerms;
