import { Col, Container, Row } from "reactstrap"
import NavbarComp from "../../../../Components/Navbar"
import { useNavigate, useParams } from "react-router-dom";
import { assests } from "../../../../Assests/assets";
import { useEffect, useState } from "react";
import { getBookingInformation } from "./api";
import AllBookinngDetails from "./details";
import DogsInfo from "./dogInfo";
import DogCareForm from "./careForm";
import { FaAngleLeft } from "react-icons/fa";


const BookingInformation = () => {
    const navigate = useNavigate();
    const {bookingId} = useParams()
    const { dog, dogInfo, careForm } = assests;
    const [bookingInformation, setBookingInformation] = useState([])
    const [errMsg, setErrMsg] = useState('')
    const [activeTab, setActiveTab] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getBookingInformation(bookingId).then((res) => {
            setIsLoading(true)
            res.data.response_code == 80 && setBookingInformation(res.data.data)
            res.data.response_code == 90 && setErrMsg('Link Expired')
        }).catch((err) => {
            err.response.status == 401 && navigate('/login')
        })
    }, [])

    return(
        <div>
            {isLoading && <div>
            {activeTab == 0 && <div className="home">
                <Container>
                    <Row>
                    <Col md="12">
                        <div className="navbar">
                        <NavbarComp />
                        </div>
                    </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className="justify-content-center align-items-center">
                    <Col md={3} xs={12} className="custom-col">
                        <div
                        onClick={() => bookingInformation.length != 0 && setActiveTab(1)}
                        className="newhome icon-container reduce-gap"
                        >
                        <img src={dogInfo} alt="Icon 1" className="custom-icon" />
                        <h4 className="title">DETAILS OF BOOKING </h4>
                        </div>
                    </Col>
                    <Col md={3} xs={12} className="custom-col">
                        <div
                        onClick={() => bookingInformation.length != 0 && setActiveTab(2)}
                        className="icon-container reduce-gap"
                        >
                        <img src={dog} alt="Icon 2" className="custom-icon" />
                        <h4 className="title">DOG DETAILS</h4>
                        </div>
                    </Col>
                    {bookingInformation.status == "Confirmed" && <Col md={3} xs={12} className="custom-col">
                        <div
                        onClick={() => bookingInformation.length != 0 && setActiveTab(3)}
                        className="icon-container reduce-gap"
                        >
                        <img src={careForm} alt="Icon 3" className="custom-icon" />
                        <h4 className="title">DOG CARE INFORMATION</h4>
                        </div>
                    </Col>}
                    </Row>
                </Container>
                <Row>
                    <h4 className="text-danger">{errMsg}</h4>
                </Row>
            </div>}

            {activeTab == 1 && <AllBookinngDetails data={bookingInformation} step={(data) => {setActiveTab(data)}}/>}
            {activeTab == 2 && <DogsInfo data={bookingInformation} step={(data) => {setActiveTab(data)}}/>}
            {activeTab == 3 && <DogCareForm data={bookingInformation} step={(data) => {setActiveTab(data)}}/>}
            </div>}
        </div>
    )
}

export default BookingInformation