import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import InteractionPlugin from "@fullcalendar/interaction";
import { useEffect, useState } from "react";
import {
  createCalenderEventreq,
  deleteCalenderEventreq,
  getCalenderEventByIdreq,
  getCalenderEventreq,
  updateCalenderEventreq,
} from "./api";
import "../../../../App.css";
import NavbarComp from "../../../../Components/Navbar";
import SweetAlert from "react-bootstrap-sweetalert";
import { getLocal, isAuthorized } from "../../../../Utils/localStorage";
import { DynamicButton } from "../../../../Components/DynamicButton";
import { useNavigate } from "react-router-dom";
import * as moment from "moment";
import { toast } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa";
import './style.css';

const Calendar = () => {
  const [eventsData, setEventsData] = useState([]);
  const [eventId, setEventId] = useState("");
  const [evenTypeErrorMsg, setEventTypeErrorMsg] = useState("");
  const [showEventAlert, setShowEventAlert] = useState(false);
  const [showEventConfirm, setShowEventConfirm] = useState(false);
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [isCalenderOption, setIsCalenderOption] = useState(false);
  const [startDateEvent, setStartDateEvent] = useState();
  const [endDateEvent, setEndDateEvent] = useState();
  const [userInfoModal, setUserInfoModal] = useState(false);
  const [updateEventDisabled, setUpdateEventDisabled] = useState(true);
  const [calenderEventData, setCalenderEventData] = useState({
    title: "",
    description: "",
    event_type: "",
    start_time: "",
    end_time: "",
    startDateTime: "",
    endDateTime: "",
  });

  const { title, description, event_type, startDateTime, endDateTime } =
    calenderEventData;

  const [eventData, setEventData] = useState({
    eventTitle: "",
    eventType: "",
    eventDescription: "",
    eventStartDateTime: "",
    eventEndDateTime: "",
  });

  const {
    eventTitle,
    eventType,
    eventDescription,
    eventStartDateTime,
    eventEndDateTime,
  } = eventData;

  const navigate = useNavigate();

  const handlUsersCalenderData = async () => {
    try {
      const { data } = await getCalenderEventreq();
      setEventsData(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      isAuthorized() &&
      window.localStorage.getItem("userStatus") === "partially_approved"
    )
      navigate("/sitter/bookingdetails");
  }, []);

  useEffect(() => {
    handlUsersCalenderData();
  }, []);

  const checkUseFirstTimeLogin = () => {
    getLocal("first_time") === "No"
      ? setFirstTimeLogin(false)
      : setFirstTimeLogin(true);
    console.log(firstTimeLogin);
  };

  useEffect(() => {
    checkUseFirstTimeLogin();
  }, []);

  const handleCloseWelcomeMsg = () => {
    setFirstTimeLogin(false);
    localStorage.removeItem("first_time");
  };

  const selectedDate = async (start) => {
    setStartDateEvent(start.startStr);
    setEndDateEvent(start.endStr);
    setCalenderEventData({
      ...calenderEventData,
      startDateTime: moment(new Date(start.startStr)).format(
        "YYYY-MM-DDT00:00"
      ),
      endDateTime: moment(new Date(start.endStr))
        .subtract(1, "days")
        .format("YYYY-MM-DDT23:59"),
    });
    setIsCalenderOption(true);
  };

  const createFilterEvent = async () => {
    if (startDateTime == "" || endDateTime == "" || event_type == "") {
      toast.warning("Please Select Event Type", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setEventTypeErrorMsg("Please Select the event type");
    } else {
      await createCalenderEventreq({
        title: title,
        description: description,
        start_time: startDateTime,
        end_time: endDateTime,
        event_type: event_type,
      });
      setIsCalenderOption(false);
      setEventTypeErrorMsg("");
      setCalenderEventData({
        title: "",
        description: "",
        event_type: "",
        startDateTime: "",
        endDateTime: "",
      });
      handlUsersCalenderData();
    }
  };

  const handleDateClick = (dateClickInfo) => {};

  const enableEventAlert = async (e) => {
    try {
      if (
        e.event._def.extendedProps.event_type === "unavailable" ||
        "booking_confirmed"
      ) {
        setEventId(e.event._def.extendedProps.event_id);
        setUserInfoModal(true);
      }
      const { data } = await getCalenderEventByIdreq(
        e.event._def.extendedProps.event_id
      );
      setEventData({
        eventTitle: data.data.title,
        eventType: data.data.event_type,
        eventDescription: data.data.description,
        eventStartDateTime: data.data.start.slice(0, 16),
        eventEndDateTime: data.data.end.slice(0, 16),
      });
      setUserInfoModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const removeEvent = async (e) => {
    await deleteCalenderEventreq(eventId);
    handlUsersCalenderData();
    setShowEventAlert(false);
    setShowEventConfirm(true);
    setUserInfoModal(false);
  };

  const handleCalenderChange = (e) => {
    setCalenderEventData({
      ...calenderEventData,
      [e.target.name]: e.target.value,
    });
  };

  const updateCalenderDataByUserId = async () => {
    try {
      const { data } = await updateCalenderEventreq(eventId, {
        title: eventTitle,
        description: eventDescription,
        event_type: eventType,
        start_time: eventStartDateTime,
        end_time: eventEndDateTime,
      });
      setUserInfoModal(false);
      handlUsersCalenderData();
      setUpdateEventDisabled(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <NavbarComp />
          </Col>
        </Row>
      </Container>
      <Container>
      <div
        className="instruction w-100 text-center"
      >
        <p className="m-0" style={{ color: "#f48220", fontWeight: "bold" }}>
          Instructions:
        </p>
        <p className="m-0 desktop-view px-5" style={{ color: "#f48220" }}>
          To select a single date please click on the date in the calendar. To
          select multiple consecutive dates please keep your mouse button
          pressed and drag to highlight all the dates you want to select on the
          calendar. They will automatically get selected and a window will open
          so you can enter event details. You can check the dates or change them
          when this window opens. 
        </p>
        <p className="m-0 mobile-view px-4" style={{ color: "#f48220" }}>
          To select a single date please keep the date pressed in the calendar
          for a few seconds. To select multiple consecutive dates press the date
          with your finger and then drag while pressing down to select all the
          dates. They will automatically get selected and a window will open so
          you can enter event details. You can check the dates or change them
          when this window opens.
        </p>
        <div
          onClick={() => navigate("/sitter/home")}
          style={{ cursor: "pointer", textAlign: "left", marginLeft: "5%" }}
        >
          <FaAngleLeft /> Back
        </div>
      </div>
      </Container>
      <div
        className="row justify-content-center"
        style={{ position: "relative", zIndex: "0" }}
      >
        <div className="mt-5" style={{ width: "700px", height: "700px" }}>
          <FullCalendar
            plugins={[dayGridPlugin, InteractionPlugin]}
            timeZone="UTC"
            initialView="dayGridMonth"
            dateClick={handleDateClick}
            selectable={true}
            select={selectedDate}
            eventClick={enableEventAlert}
            events={eventsData}
            displayEventTime={false}
            longPressDelay={0}
          />
        </div>
      </div>

      {firstTimeLogin && (
        <Container
          fluid
          className="contain"
          style={{ position: "absolute", top: "0" }}
        >
          <Row className="parent-wrap">
            <div className="start-application-wrapper">
              <h1>Welcome</h1>
              <p>
                Thank you for completing our application process and welcome to
                your DogStays Calendar. You can use this calendar to mark those
                dates when you will not be available for bookings. We will
                connect you with dog parents and dogs accordingly and will not
                check with you for bookings on these dates. You can
                block/unblock these dates anytime by logging in to this space.
                Please update your calendar now by selecting the month and dates
              </p>

              <DynamicButton
                createBtnText="Start"
                clickFunction={handleCloseWelcomeMsg}
              />
            </div>
          </Row>
        </Container>
      )}
      {showEventAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={removeEvent}
          onCancel={() => setShowEventAlert(false)}
        ></SweetAlert>
      ) : null}

      {showEventConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowEventAlert(false);
            setShowEventConfirm(false);
          }}
        >
          Event has been Deleted
        </SweetAlert>
      ) : null}

      {userInfoModal && (
        <div>
          <Modal size="lg" isOpen={userInfoModal} backdrop="static">
            <ModalHeader>Sitter`s Calendar Events Data</ModalHeader>
            <ModalBody>
              <Row className="justify-content-end pe-3">
                {updateEventDisabled &&
                  (eventType === "temporary_blocked" ||
                  eventType === "in_active" ? null : (
                    <>
                      <Button
                        onClick={() => {
                          setUpdateEventDisabled(false);
                        }}
                        style={{ width: "auto" }}
                        color="primary"
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => {
                          setShowEventAlert(true);
                        }}
                        style={{ width: "auto", marginLeft: "10px" }}
                        color="danger"
                      >
                        Delete
                      </Button>
                    </>
                  ))}
              </Row>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="eventStartDateTime">
                        Start Date Time
                      </Label>
                      <Input
                        name="eventStartDateTime"
                        value={eventStartDateTime}
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            eventStartDateTime: e.target.value,
                          });
                        }}
                        type="datetime-local"
                        className="form-control"
                        id="eventStartDateTime"
                        disabled={updateEventDisabled}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="eventEndDateTime">End Date Time</Label>
                      <Input
                        name="eventEndDateTime"
                        value={eventEndDateTime}
                        onChange={(e) => {
                          setEventData({
                            ...eventData,
                            eventEndDateTime: e.target.value,
                          });
                        }}
                        type="datetime-local"
                        className="form-control"
                        id="eventEndDateTime"
                        min={startDateTime}
                        disabled={updateEventDisabled}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Label htmlFor="updatedTitle">Title</Label>
                <Input
                  name="updatedTitle"
                  value={eventTitle}
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      eventTitle: e.target.value,
                    });
                  }}
                  type="text"
                  className="form-control"
                  id="updatedTitle"
                  disabled={updateEventDisabled}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="updatedDescription">Description</Label>
                <Input
                  name="updatedDescription"
                  value={eventDescription}
                  onChange={(e) =>
                    setEventData({
                      ...eventData,
                      eventDescription: e.target.value,
                    })
                  }
                  type="textarea"
                  className="form-control"
                  id="updatedDescription"
                  disabled={updateEventDisabled}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={eventType}
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      eventType: e.target.value,
                    });
                  }}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                  disabled={updateEventDisabled}
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                  <option value="temporary_blocked" disabled>
                    Temporary Blocked
                  </option>
                </Input>
              </FormGroup>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    {!updateEventDisabled && (
                      <Button
                        className="btn btn-info w-75"
                        type="button"
                        onClick={updateCalenderDataByUserId}
                      >
                        Update
                      </Button>
                    )}
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setUserInfoModal(false);
                        setUpdateEventDisabled(true);
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}

      {isCalenderOption && (
        <div>
          <Modal size="lg" isOpen={isCalenderOption} backdrop="static">
            <ModalHeader>Add Calender Event</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="startDateTime">Start Date Time</Label>
                    <Input
                      name="startDateTime"
                      value={startDateTime}
                      onChange={handleCalenderChange}
                      type="datetime-local"
                      className="form-control"
                      id="startDateTime"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="endDateTime">End Date Time</Label>
                    <Input
                      name="endDateTime"
                      value={endDateTime}
                      onChange={handleCalenderChange}
                      type="datetime-local"
                      className="form-control"
                      id="endDateTime"
                      min={startDateTime}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <Input
                  name="title"
                  value={title}
                  onChange={handleCalenderChange}
                  type="text"
                  className="form-control"
                  id="title"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <Input
                  name="description"
                  value={description}
                  onChange={handleCalenderChange}
                  type="text"
                  className="form-control"
                  id="description"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={event_type}
                  onChange={handleCalenderChange}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                </Input>
                <p className="text-danger">{evenTypeErrorMsg}</p>
              </FormGroup>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    <Button
                      className="btn btn-success w-75"
                      type="button"
                      onClick={createFilterEvent}
                    >
                      Create
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setIsCalenderOption(false);
                        setCalenderEventData({
                          ...calenderEventData,
                          title: "",
                          description: "",
                          event_type: "",
                          start_time: "",
                          end_time: "",
                          startDateTime: "",
                          endDateTime: "",
                        });
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
};
export default Calendar;
