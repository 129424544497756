import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from 'reactstrap';
import { assests } from '../../Assests/assets';
import { clearLocal, getLocal } from '../../Utils/localStorage';
import './style.css'

const NavbarComp = (props) => {
    const navigate = useNavigate()

    const handleLogout = () => {
        clearLocal()
        navigate('/login')
    }
    const [isOpen, setIsOpen] = useState()
    const isAuthorised = localStorage.getItem('token')

    const toggleNavbar = () => {
        if(isOpen){
            setIsOpen(false)
        }
       else{
        setIsOpen(true)
       }
    }

    // <p className='m-0'>{getLocal('userDataFName') + ' ' + getLocal('userDataLName')}</p>
    //     <p class="nav-link m-0 text-danger" onClick={handleLogout}>Logout</p>

  return (
    <Navbar style={{background: 'white'}} light className="pt-3 container" expand="md">
          <NavbarBrand><img src={assests.dogstaysLogo} alt='DogStays' className='brand-logo'/></NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} />
          <Collapse isOpen={isOpen} navbar>
            <div className="w-100">
                <div className='conditional-styles'>
                {isAuthorised && <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="nav-menu-login text-black" nav caret>
                {getLocal('userDataFName') + ' ' + getLocal('userDataLName')}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                  <p class="nav-link m-0 text-danger" onClick={handleLogout}>Logout</p>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>}
                  {/* <img className="Luxembourg-img ms-5" src={assests.Luxembourg}></img>
                  <a href="https://www.instagram.com/dogstays/" target="_blank"><img className="insta-img ms-3" src={assests.insta}></img></a>
                  <a href="https://www.facebook.com/DogStays" target="_blank"><img className="fb-img ms-2" src={assests.fb}></img></a>  */}
                </div>
              </div>
          </Collapse>
        </Navbar>
  );
}

export default NavbarComp;