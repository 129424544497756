import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Form, Row, Col } from "reactstrap";
import Select from "react-select";
import { FaPen, FaWindowClose } from "react-icons/fa";

const size_of_dog_options = [
  { value: "small", label: "Small (less than 14 kilos)" },
  { value: "medium", label: "Medium (between 14 and 25 kilos)" },
  { value: "large", label: "Large (more than 25 kilos)" },
  { value: "all_size", label: "All sizes OK" },
];

const age_of_dog_options = [
  { value: "young_puppy", label: "Very Young Puppy (less than 1 year old)" },
  { value: "puppy", label: "Puppy (less than 2 years old)" },
  { value: "adult_dog", label: "Adult dog (over 2 years old)" },
  { value: "senior_dog", label: "Senior dog (above 7 years old)" },
  { value: "all_age", label: "All ages welcome" },
];

const offered_services_options = [
  {
    value: "overnight_sitter_home",
    label: "Overnight Boarding Sitter's Home",
  },
  {
    value: "overnight_parent_home",
    label: "Overnight Boarding Dog Parent's Home",
  },
  { value: "daycare_sitter_home", label: "Daycare Sitter's Home" },
  { value: "daycare_parent_home", label: "Daycare Dog Parent's Home" },
];

const formatGetMultiSelect = (originalarr, arr) => {
  const res1 = originalarr
    .filter((item) => arr?.includes(item.label))
    .map((item) => ({
      value: item.value,
      label: item.label,
    }));

  return res1;
  // const res = arr?.map((item) => {
  //   return {
  //     value: item,
  //     label: item,
  //   };
  // });
  //return res;
};

const formatPostMultiSelect = (arr) => {
  const res = arr?.map(({ value }) => value);
  return res;
};

const BookingPreference = ({ data, updateData }) => {
  const {
    offered_services,
    size_of_dog,
    age_of_dog,
    comfortable_with_non_toilet_trained,
    comfortable_with_two_dogs,
    comfortable_with_separation_anxiety,
    comfortable_with_cat_or_other,
  } = data || {};
  const initialFormData = {
    offered_services:
      formatGetMultiSelect(offered_services_options, offered_services) || [],
    size_of_dog: formatGetMultiSelect(size_of_dog_options, size_of_dog) || [],
    age_of_dog: formatGetMultiSelect(age_of_dog_options, age_of_dog) || [],
    comfortable_with_non_toilet_trained:
      comfortable_with_non_toilet_trained || "",
    comfortable_with_two_dogs: comfortable_with_two_dogs || "",
    comfortable_with_separation_anxiety:
      comfortable_with_separation_anxiety || "",
    comfortable_with_cat_or_other: comfortable_with_cat_or_other || "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [validationMessage, setValidationMessage] = useState("");
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (data) {
      setFormData(initialFormData);
    }
  }, [data]);

  useEffect(() => {
    if (!editMode) {
      setFormData(initialFormData);
    }
  }, [editMode]);

  const handleService = (selectedOptions) => {
    if (editMode) {
      //const selectedValues = selectedOptions.map((option) => option.value);
      setFormData({
        ...formData,
        offered_services: selectedOptions,
      });
    }
  };

  const handleSize = (selectedOptions) => {
    if (editMode) {
      //const selectedValues = selectedOptions.map((option) => option.value);
      setFormData({
        ...formData,
        size_of_dog: selectedOptions,
      });
    }
  };

  const handleAge = (selectedOptions) => {
    if (editMode) {
      //const selectedValues = selectedOptions.map((option) => option.value);
      setFormData({
        ...formData,
        age_of_dog: selectedOptions,
      });
    }
  };

  const handleInputChange = (e) => {
    if (editMode) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    // Define an array to store validation errors
    const validationErrors = {};

    if (formData.offered_services.length === 0) {
      validationErrors["offered_services"] =
        "Please select at least one offered service.";
    }
    if (formData.size_of_dog.length === 0) {
      validationErrors["size_of_dog"] =
        "Please select at least one size of dog.";
    }
    if (formData.age_of_dog.length === 0) {
      validationErrors["age_of_dog"] = "Please select at least one age of dog.";
    }
    if (!formData.comfortable_with_non_toilet_trained) {
      validationErrors["comfortable_with_non_toilet_trained"] =
        "Please select if you are comfortable with non-toilet trained dogs.";
    }
    if (!formData.comfortable_with_two_dogs) {
      validationErrors["comfortable_with_two_dogs"] =
        "Please select if you are comfortable with two dogs from the same house.";
    }
    if (!formData.comfortable_with_separation_anxiety) {
      validationErrors["comfortable_with_separation_anxiety"] =
        "Please select if you are comfortable with dogs with separation anxiety or other problems.";
    }
    if (!formData.comfortable_with_cat_or_other) {
      validationErrors["comfortable_with_cat_or_other"] =
        "Please select if you are comfortable with cats or other small animals.";
    }

    // Check if there are any validation errors
    if (Object.keys(validationErrors).length > 0) {
      setValidationMessage(validationErrors);
    } else {
      // If there are no validation errors, proceed with form submission
      const payload = {
        offered_services: formatPostMultiSelect(formData.offered_services),
        size_of_dog: formatPostMultiSelect(formData.size_of_dog),
        age_of_dog: formatPostMultiSelect(formData.age_of_dog),
        comfortable_with_non_toilet_trained:
          formData.comfortable_with_non_toilet_trained,
        comfortable_with_two_dogs: formData.comfortable_with_two_dogs,
        comfortable_with_separation_anxiety:
          formData.comfortable_with_separation_anxiety,
        comfortable_with_cat_or_other: formData.comfortable_with_cat_or_other,
      };

      // You can now use the payload to save the data or perform further actions
      await updateData(payload);
      setEditMode(false);
      // You can perform any further actions or API calls here for saving the data
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: editMode ? "#f9eae3" : provided.backgroundColor,
    }),
    multiValueRemove: (base) => {
      return !editMode ? { ...base, display: "none" } : { ...base };
    },
  };

  return (
    <>
      <Form>
        <Row>
          <Col md="12">
            <div style={{ float: "right", marginRight: "10px" }}>
              {editMode ? (
                <FaWindowClose
                  onClick={() => {
                    setEditMode(false);
                    setValidationMessage("");
                  }}
                />
              ) : (
                <FaPen
                  onClick={() => {
                    setEditMode(true);
                    setValidationMessage("");
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="offered_services">
                What services would you like to offer with DogStays?
              </Label>
              <Select
                className="select-font"
                options={offered_services_options}
                isMulti
                onChange={handleService}
                value={formData.offered_services}
                name="offered_services"
                placeholder="Select"
                isDisabled={!editMode}
                styles={customStyles}
              />
              {validationMessage.offered_services && (
                <div style={{ color: "red" }}>
                  {validationMessage.offered_services}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="size_of_dog">
                What all sizes of dogs are you comfortable caring for?
              </Label>
              <Select
                className="select-font"
                options={size_of_dog_options}
                isMulti
                onChange={handleSize}
                value={formData.size_of_dog}
                name="size_of_dog"
                placeholder="Select"
                isDisabled={!editMode}
                styles={customStyles}
              />
              {validationMessage.size_of_dog && (
                <div style={{ color: "red" }}>
                  {validationMessage.size_of_dog}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="age_of_dog">
                You are comfortable caring for dogs of what ages?
              </Label>
              <Select
                className="select-font"
                options={age_of_dog_options}
                isMulti
                onChange={handleAge}
                value={formData.age_of_dog}
                name="age_of_dog"
                placeholder="Select"
                maxMenuHeight={120}
                isDisabled={!editMode}
                styles={customStyles}
              />
              {validationMessage.age_of_dog && (
                <div style={{ color: "red" }}>
                  {validationMessage.age_of_dog}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="comfortable_with_non_toilet_trained">
                Are you comfortable caring for young dogs who are still being
                toilet trained?
              </Label>
              <Input
                name="comfortable_with_non_toilet_trained"
                placeholder="Enter Detail"
                value={formData.comfortable_with_non_toilet_trained}
                onChange={handleInputChange}
                type="select"
                errorMessage="comfortable_with_non_toilet_trained is required"
                className="form-control"
                id="comfortable_with_non_toilet_trained"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </Input>
              {validationMessage.comfortable_with_non_toilet_trained && (
                <div style={{ color: "red" }}>
                  {validationMessage.comfortable_with_non_toilet_trained}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <Label htmlFor="comfortable_with_two_dogs">
                Are you comfortable caring for 2 dogs from the same house?
              </Label>
              <Input
                name="comfortable_with_two_dogs"
                placeholder="Enter Detail"
                value={formData.comfortable_with_two_dogs}
                onChange={handleInputChange}
                type="select"
                errorMessage="comfortable_with_two_dogs is required"
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="comfortable_with_two_dogs"
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </Input>
              {validationMessage.comfortable_with_two_dogs && (
                <div style={{ color: "red" }}>
                  {validationMessage.comfortable_with_two_dogs}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="comfortable_with_separation_anxiety">
                Would you be open to caring for dogs with separation anxiety or
                other problems?
              </Label>
              <Input
                name="comfortable_with_separation_anxiety"
                placeholder="Enter Detail"
                value={formData.comfortable_with_separation_anxiety}
                onChange={handleInputChange}
                type="select"
                errorMessage="comfortable_with_separation_anxiety is required"
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="comfortable_with_separation_anxiety"
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </Input>
              {validationMessage.comfortable_with_separation_anxiety && (
                <div style={{ color: "red" }}>
                  {validationMessage.comfortable_with_separation_anxiety}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="comfortable_with_cat_or_other">
                Are you comfortable caring for cats or other small animals?
              </Label>
              <Input
                name="comfortable_with_cat_or_other"
                placeholder="Enter Detail"
                value={formData.comfortable_with_cat_or_other}
                onChange={handleInputChange}
                type="select"
                errorMessage="comfortable_with_cat_or_other is required"
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="comfortable_with_cat_or_other"
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </Input>
              {validationMessage.comfortable_with_cat_or_other && (
                <div style={{ color: "red" }}>
                  {validationMessage.comfortable_with_cat_or_other}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        {editMode && (
          <Row>
            <Col md="6">
              <FormGroup>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Update
                </button>
              </FormGroup>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
};

export default BookingPreference;
