import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import BasicInfo from "./BasicInfo";
import HomeSchedule from "./HomeSchedule";
import BookingPreference from "./BookingPrefrence";
import PreviousExperience from "./PreviousExperience";
import "./style.css";
import NavbarComp from "../../../../Components/Navbar";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { fetchData, updateSitterFormData } from "./api";
import { toast } from "react-toastify";

const PersonalInformation = () => {
  const [open, setOpen] = useState(null);
  const [data, setData] = useState(null);

  const toggle = (id) => {
    if (open === id) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };

  const getData = async () => {
    try {
      const result = await fetchData();
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateData = async (payload) => {
    try {
      const result = await updateSitterFormData(payload);
      const message = payload?.password ? 'Password Updated Successfully' : "Updated successfully"
      const { response_code, developer_message } = result.data;
      if (response_code === 80) {
        getData();
        toast.success(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      } else {
        toast.error(developer_message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Data could not be updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Container>
        <Row>
          <Col md="12">
            <div className="navbar">
              <NavbarComp />
            </div>
            <div className="back">
              <span
                onClick={() => navigate("/sitter/home")}
                style={{ cursor: "pointer"}}
              >
                <FaAngleLeft /> Back
              </span>
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <Container>
          <Row style={{ padding: "20px 0px" }}>
            <Col lg="12">
              <h2 className="Form-title mb-2 text-center">
                Your DogStays profile
              </h2>
              <div id="basic-pills-wizard" className="twitter-bs-wizard">
                <Accordion open={open} toggle={toggle}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      Basic Information
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <CardBody>
                        <BasicInfo data={data} updateData={updateData} />
                      </CardBody>
                    </AccordionBody>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionHeader targetId="2">
                      Your Home & Schedule
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                      <CardBody>
                        <HomeSchedule data={data} updateData={updateData} />
                      </CardBody>
                    </AccordionBody>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionHeader targetId="3">
                      Booking Preferences
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                      <CardBody>
                        <BookingPreference
                          data={data}
                          updateData={updateData}
                        />
                      </CardBody>
                    </AccordionBody>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionHeader targetId="4">
                      Your Previous Experience With Dogs
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                      <CardBody>
                        <PreviousExperience
                          data={data}
                          updateData={updateData}
                        />
                      </CardBody>
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PersonalInformation;
