
import axios from "axios"
import { toast } from "react-toastify"
import { baseDomain, sitterUserForm } from "../../../../Constants/apiRoutes"
import { getLocal } from "../../../../Utils/localStorage";


export const createSitterKycReq = (data) => {
  const token = getLocal('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
};
    return  axios.put(`${baseDomain}account/sitter/profile/form`, data, config)
  }