import { Col, Container, Row } from "reactstrap"
import NavbarComp from "../../../../Components/Navbar"
import { FaAngleLeft } from "react-icons/fa";
import DogCareFormData from "./DogCareForm";
import { useState } from "react";


const DogCareForm = ({data, step}) => {
    const [activeTab, setActiveTab] = useState(0)
    const [selectedDogData, setSelectedDogData] = useState({})
    return(
       <>
         {activeTab == 0 && <div className="home">
        <Container>
            <Row>
                <Col md="12">
                    <div className="navbar">
                    <NavbarComp />
                    </div>
                    <h2 className="text-center w-100">Dog List</h2>
                    <div className="back">
                        <span
                           onClick={() => step(0)}
                            style={{ cursor: "pointer"}}
                        >
                         <FaAngleLeft /> Back
                        </span>
                    </div>
                </Col>
            </Row>
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center', gap: '20px', cursor: 'pointer'}}>
                {data && data.dogs.map((res, index) => {
                    console.log(res, 'ressssss')
                    return(
                        <div onClick={() => {setSelectedDogData(data.dogs[index]); setActiveTab(1)}}>
                            <img style={{borderRadius: '50%', border: "5px solid #f48221"}} width={150} height={150} src={res.photos[0]}/>
                            <p>{res.name}</p>
                        </div>
                    )
                })}
            </div>
        </Container>
        </div>}
        {selectedDogData && activeTab == 1 && <DogCareFormData data={selectedDogData} step={(data) => setActiveTab(data)}/>}
       </>
    )
}

export default DogCareForm