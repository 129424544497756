import { useState } from "react"
import { Collapse, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, Nav, DropdownItem } from "reactstrap"
import { assests } from "../../Assests/assets"
import './style.css'

const GlobalNav = () => {
    const [isOpen, setIsOpen] = useState()

    const toggleNavbar = () => {
        if(isOpen){
            setIsOpen(false)
        }
       else{
        setIsOpen(true)
       }
    }
    return(
        <>
        <Navbar style={{background: 'white'}} light className="container-fluid col-md-12 pt-3" expand="md">
        <div className="row w-100">
        <div className="col-md-11 nav-desktop-wrapper">
          <div className="col-md-3 mobnav-setup text-center">
          <NavbarBrand><img className="brand-logo" src={assests.dogstaysLogo} alt='DogStays'/></NavbarBrand>
          <NavbarToggler  onClick={toggleNavbar} />
          </div>
          <div className="col-md-9">
          <Collapse className="align-nav-menu" isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="nav-style">
                <NavLink className="nav-menu" href="https://www.dogstays.lu/">Home</NavLink>
              </NavItem>
              <NavItem className="nav-style nav-border">
                <NavLink className="nav-menu" href="https://www.dogstays.lu/book">Book Dog Sitter</NavLink>
              </NavItem>
              <NavItem className="nav-style nav-border">
                <NavLink className="nav-menu" href="https://www.dogstays.lu/faqs">FAQs</NavLink>
              </NavItem>
              <NavItem className="nav-style nav-border">
                <NavLink className="nav-menu" href="https://www.dogstays.lu/pricing">Pricing</NavLink>
              </NavItem>
              <NavItem className=" nav-style nav-border">
                <NavLink className={window.location.pathname === '/startUserApplication' ? 'nav-menu become-sitter' : 'nav-menu'}   href="https://sitter.dogstays.lu/startUserApplication">Become a Dog Sitter</NavLink>
              </NavItem>
            </Nav>
            <div className="w-25">
                <div className="d-flex align-items-end">
                  <img className="Luxembourg-img" src={assests.Luxembourg}></img>
                  <a href="https://www.instagram.com/dogstays/" target="_blank"><img className="insta-img ms-3" src={assests.insta}></img></a>
                  <a href="https://www.facebook.com/DogStays" target="_blank"><img className="fb-img ms-2" src={assests.fb}></img></a> 
                </div>
              </div>
          </Collapse>
          </div>
          </div>
        </div>
        </Navbar>
        </>
    )
}

export default GlobalNav