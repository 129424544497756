import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import classnames from "classnames";
import { useMemo, useState } from "react";
import "./style.css";
import { DynamicButton } from "../../../../Components/DynamicButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { createSitterReq } from "./api";
import { toast } from "react-toastify";
import countryList from "react-select-country-list";
import { useNavigate } from "react-router-dom";


const SitterForm = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(1);
  const [mob, setMob] = useState("");
  const [mobWhatsapp, setMobWhatsapp] = useState("");
  const [preferedLanguage, setPreferedLanguage] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [isAlertModal, setIsAlertModal] = useState(false);
  const [isPasswordAlertModal, setIsPasswordAlertModal] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [cnfPasswordMsg, setCnfPasswordMsg] = useState("");
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [formData, setFormData] = useState({
    hear_from_source: "",
    other_hear_from_source: "",
    first_name: "",
    last_name: "",
    gender: "",
    date_of_birth: "",
    mobile: "",
    using_whatsapp_messenger: "",
    another_whatsapp_mobile: "",
    preferred_languages: [],
    street_name: "",
    city: "",
    country: "",
    postal_code: "",
    email: "",
    password: "",
    cnfPassword: "",
    reason_of_joining: "",
    have_your_own_dog: "",
    sitter_dog_info: "",
    have_your_own_cat: "",
    has_previous_experience_for_own_dog: "",
    own_dog_experience_details: "",
    has_previous_experience_as_dog_sitter: "",
    as_sitter_experience_details: "",
    has_formal_training: "",
    formal_training_details: "",
    cared_for_other_than_dog: "",
    caring_details_for_other_than_dog: "",
  });

  const {
    hear_from_source,
    other_hear_from_source,
    first_name,
    last_name,
    gender,
    date_of_birth,
    mobile,
    using_whatsapp_messenger,
    another_whatsapp_mobile,
    preferred_languages,
    street_name,
    city,
    country,
    postal_code,
    email,
    password,
    cnfPassword,
    reason_of_joining,
    have_your_own_dog,
    sitter_dog_info,
    have_your_own_cat,
    has_previous_experience_for_own_dog,
    own_dog_experience_details,
    has_previous_experience_as_dog_sitter,
    as_sitter_experience_details,
    has_formal_training,
    formal_training_details,
    cared_for_other_than_dog,
    caring_details_for_other_than_dog,
  } = formData;

  const [isPrivacyAlertModal, setIsPrivacyAlertModal] = useState(false);
  const [validateCheck, setValidateCheck] = useState(false);
  const handleCheck = (e) => {
    e.target.checked ? setValidateCheck(true) : setValidateCheck(false);
  };


  const viewPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else if (passwordType === "text") {
      setPasswordType("password");
    }
  };

  const viewConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else if (confirmPasswordType === "text") {
      setConfirmPasswordType("password");
    }
  };

  const handleCnfPasswordAlert = () => {
    setCnfPasswordMsg("");
  };

  const vaildPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/;

  const changeHandler = (value) => {
    setCountryValue(value);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setActiveTab(tab);
      }
    }
  };

  const handleMobile = (val) => {
    setMob(val);
  };

  const handleMobileWhatsapp = (val) => {
    setMobWhatsapp(val);
  };

  const handleLanguage = (val) => {
    setPreferedLanguage(val);
    console.log(formatMultiSelectData(preferedLanguage));
  };

  const formatMultiSelectData = (data) => {
    const formatedData = data.map(({ value }) => value);
    return formatedData;
  };

  const formatCountrySelectData = (data) => {
    const formatedData = data.label;
    return formatedData;
  };

  const options = [
    { value: "English", label: "English" },
    { value: "French", label: "French" },
    { value: "German", label: "German" },
    { value: "Luxembourgish", label: "Luxembourgish" },
    { value: "Spanish", label: "Spanish" },
    { value: "Italian", label: "Italian" },
    { value: "Portugese", label: "Portugese" },
    { value: "Dutch", label: "Dutch" },

    { value: "Akan", label: "Akan" },
    { value: "Amharic", label: "Amharic" },
    { value: "Arabic", label: "Arabic" },
    { value: "Assamese", label: "Assamese" },
    { value: "Awadhi", label: "Awadhi" },
    { value: "Azerbaijani", label: "Azerbaijani" },
    { value: "Balochi", label: "Balochi" },
    { value: "Belarusian", label: "Belarusian" },
    { value: "Bengali", label: "Bengali" },
    { value: "Bhojpuri", label: "Bhojpuri" },
    { value: "Burmese", label: "Burmese" },
    { value: "Cebuano (Visayan)", label: "Cebuano (Visayan)" },
    { value: "Chewa", label: "Chewa" },
    { value: "Chhattisgarhi", label: "Chhattisgarhi" },
    { value: "Chittagonian", label: "Chittagonian" },
    { value: "Czech", label: "Czech" },
    { value: "Deccan", label: "Deccan" },
    { value: "Dhundhari", label: "Dhundhari" },
    { value: "Eastern Min", label: "Eastern Min" },
    { value: "Fula", label: "Fula" },
    { value: "Gan Chinese", label: "Gan Chinese" },
    { value: "Greek", label: "Greek" },
    { value: "Gujarati", label: "Gujarati" },
    { value: "Haitian Creole", label: "Haitian Creole" },
    { value: "Hakka", label: "Hakka" },
    { value: "Haryanvi", label: "Haryanvi" },
    { value: "Hausa", label: "Hausa" },
    { value: "Hiligaynon", label: "Hiligaynon" },
    { value: "Hindi", label: "Hindi" },
    { value: "Hmong", label: "Hmong" },
    { value: "Hungarian", label: "Hungarian" },
    { value: "Igbo", label: "Igbo" },
    { value: "Ilocano", label: "Ilocano" },
    { value: "Italian", label: "Italian" },
    { value: "Japanese", label: "Japanese" },
    { value: "Javanese", label: "Javanese" },
    { value: "Jin", label: "Jin" },
    { value: "Kannada", label: "Kannada" },
    { value: "Kazakh", label: "Kazakh" },
    { value: "Khmer", label: "Khmer" },
    { value: "Kinyarwanda", label: "Kinyarwanda" },
    { value: "Kirundi", label: "Kirundi" },
    { value: "Konkani", label: "Konkani" },
    { value: "Korean", label: "Korean" },
    { value: "Kurdish", label: "Kurdish" },
    { value: "Madurese", label: "Madurese" },
    { value: "Magahi", label: "Magahi" },
    { value: "Maithili", label: "Maithili" },
    { value: "Malagasy", label: "Malagasy" },
    { value: "Malay/Indonesian", label: "Malay/Indonesian" },
    { value: "Malayalam", label: "Malayalam" },
    { value: "Mandarin", label: "Mandarin" },
    { value: "Marathi", label: "Marathi" },
    { value: "Marwari", label: "Marwari" },
    { value: "Mossi", label: "Mossi" },
    { value: "Nepali", label: "Nepali" },
    { value: "Northern Min", label: "Northern Min" },
    { value: "Odia (Oriya)", label: "Odia (Oriya)" },
    { value: "Oromo", label: "Oromo" },
    { value: "Pashto", label: "Pashto" },
    { value: "Persian", label: "Persian" },
    { value: "Polish", label: "Polish" },
    { value: "Punjabi", label: "Punjabi" },
    { value: "Quechua", label: "Quechua" },
    { value: "Romanian", label: "Romanian" },
    { value: "Russian", label: "Russian" },
    { value: "Saraiki", label: "Saraiki" },
    { value: "Serbo-Croatian", label: "Serbo-Croatian" },
    { value: "Shona", label: "Shona" },
    { value: "Sindhi", label: "Sindhi" },
    { value: "Sinhalese", label: "Sinhalese" },
    { value: "Somali", label: "Somali" },
    { value: "Southern Min", label: "Southern Min" },
    { value: "Sundanese", label: "Sundanese" },
    { value: "Swedish", label: "Swedish" },
    { value: "Sylheti", label: "Sylheti" },
    { value: "Tagalog", label: "Tagalog" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
    { value: "Thai", label: "Thai" },
    { value: "Turkish", label: "Turkish" },
    { value: "Turkmen", label: "Turkmen" },
    { value: "Ukrainian", label: "Ukrainian" },
    { value: "Urdu", label: "Urdu" },
    { value: "Uyghur", label: "Uyghur" },
    { value: "Uzbek", label: "Uzbek" },
    { value: "Vietnamese", label: "Vietnamese" },
    { value: "Wu (inc. Shanghainese)", label: "Wu (inc. Shanghainese)" },
    { value: "Xhosa", label: "Xhosa" },
    { value: "Xiang (Hunnanese)", label: "Xiang (Hunnanese)" },
    { value: "Yoruba", label: "Yoruba" },
    { value: "Yue (Cantonese)", label: "Yue (Cantonese)" },
    { value: "Zhuang", label: "Zhuang" },
    { value: "Zulu", label: "Zulu" },
  ];
  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      hear_from_source === "" ||
      first_name === "" ||
      last_name === "" ||
      gender === "" ||
      date_of_birth === "" ||
      mob === "" ||
      preferedLanguage === "" ||
      street_name === "" ||
      city === "" ||
      countryValue === "" ||
      postal_code === "" ||
      email === "" ||
      password === "" ||
      reason_of_joining === "" ||
      have_your_own_cat === "" ||
      has_previous_experience_for_own_dog === "" ||
      has_previous_experience_as_dog_sitter === "" ||
      has_formal_training === "" ||
      cared_for_other_than_dog === ""
    ) {
      toast.warning("Please Fill All The Fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      if (password !== cnfPassword) {
        toast.error("Password Doesn`t Match", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      } else {
        try {
          const { data } = await createSitterReq({
            hear_from_source,
            other_hear_from_source,
            first_name,
            last_name,
            gender,
            date_of_birth,
            mobile: mob,
            using_whatsapp_messenger,
            another_whatsapp_mobile: mobWhatsapp,
            preferred_languages: formatMultiSelectData(preferedLanguage),
            street_name,
            city,
            country: formatCountrySelectData(countryValue),
            postal_code,
            email,
            password,
            reason_of_joining,
            have_your_own_dog,
            sitter_dog_info,
            have_your_own_cat,
            has_previous_experience_for_own_dog,
            own_dog_experience_details,
            has_previous_experience_as_dog_sitter,
            as_sitter_experience_details,
            has_formal_training,
            formal_training_details,
            cared_for_other_than_dog,
            caring_details_for_other_than_dog,
          });

          if (data.response_code === 91) {
            setIsAlertModal(true);
            setAlertMsg(data.developer_message);
          } else {
            data.response_code === 80
              ? navigate("/sitterformsubmit")
              : toast.error(data.developer_message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <>
      <div className="form-main">
        <Container fluid={false} className="">
          <Row style={{ padding: "20px 0px" }}>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h2 className="Form-title mb-2 text-center">
                    Joining The DogStays Sitter Network
                  </h2>
                  <p className="info-msg">In case you face any problems with this form please email us at dogstays@gmail.com or contact@dogstays.lu and we will help.</p>
                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === 1,
                          })}
                        >
                          <span className="step-number">01</span>
                          <span className="step-title">Your Details</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === 2,
                          })}
                        >
                          <span className="step-number">02</span>
                          <span className="step-title">Address</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === 3,
                          })}
                        >
                          <span className="step-number">03</span>
                          <span className="step-title">Experience</span>
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === 4,
                          })}
                        >
                          <span className="step-number">04</span>
                          <span className="step-title">Submitted</span>
                        </NavLink>
                      </NavItem> */}
                    </ul>
                    <TabContent
                      activeTab={activeTab}
                      className="twitter-bs-wizard-tab-content pt-0"
                    >
                      <div
                        className={
                          activeTab === 4
                            ? "d-none"
                            : "row justify-content-center"
                        }
                      >
                        <ul className="info-list-form1 w-75">
                          <li>
                            Please make sure the form is complete. We need these
                            details for the next step in your application
                            process.
                          </li>
                          <li>
                            Also, please make a note of the email and password
                            you create below since you will need this later to
                            login.
                          </li>
                        </ul>
                      </div>
                      <TabPane tabId={1}>
                        <Form>
                          <Row>
                            <Col md="6" className="division">
                              <FormGroup>
                                <Label htmlFor="hear_from_source">
                                  How did you hear about DogStays?
                                </Label>
                                <Input
                                  name="hear_from_source"
                                  placeholder="Enter Hear From Source"
                                  value={hear_from_source}
                                  onChange={handelInput}
                                  type="select"
                                  errorMessage="hear_from_source is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="hear_from_source"
                                >
                                  <option hidden>Select</option>
                                  <option value="Google Search">
                                    Google Search
                                  </option>
                                  <option value="Facebook group">
                                    Facebook group
                                  </option>
                                  <option value=" Through a friend">
                                    {" "}
                                    Through a friend
                                  </option>
                                  <option value="Booked With DogStays before">
                                    Booked With DogStays before
                                  </option>
                                  <option value="Facebook Ad">
                                    Facebook Ad
                                  </option>
                                  <option value="Google Ad">Google Ad</option>
                                  <option value="Other">Other</option>
                                </Input>
                              </FormGroup>

                              {hear_from_source === "Other" && (
                                <FormGroup>
                                  <Label htmlFor="other_hear_from_source">
                                    {" "}
                                    Please tell us how you heard of DogStays
                                  </Label>
                                  <Input
                                    name="other_hear_from_source"
                                    placeholder="Please Explain"
                                    value={other_hear_from_source}
                                    onChange={handelInput}
                                    type="textarea"
                                    errorMessage="other_hear_from_source is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="other_hear_from_source"
                                    required
                                  />
                                </FormGroup>
                              )}

                              <FormGroup>
                                <Label htmlFor="first_name">Name</Label>
                                <Input
                                  name="first_name"
                                  placeholder="Enter Name"
                                  value={first_name}
                                  onChange={handelInput}
                                  type="text"
                                  errorMessage="first_name is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="first_name"
                                />
                              </FormGroup>

                              <FormGroup>
                                <Label htmlFor="last_name">Family Name</Label>
                                <Input
                                  name="last_name"
                                  placeholder="Enter Family Name"
                                  value={last_name}
                                  onChange={handelInput}
                                  type="text"
                                  errorMessage="last_name is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="last_name"
                                />
                              </FormGroup>

                              <FormGroup>
                                <Label htmlFor="gender">Gender</Label>
                                <Input
                                  name="gender"
                                  placeholder="Enter gender"
                                  value={gender}
                                  onChange={handelInput}
                                  type="select"
                                  errorMessage="gender is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="gender"
                                >
                                  <option hidden>Select Gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </Input>
                              </FormGroup>

                              <FormGroup>
                                <Label htmlFor="date_of_birth">
                                  Date of birth
                                </Label>
                                <Input
                                  name="date_of_birth"
                                  placeholder="Enter DOB"
                                  value={date_of_birth}
                                  onChange={handelInput}
                                  max= {new Date(new Date().setFullYear(new Date().getFullYear() - 15)).toISOString().split("T")[0]}
                                  type="date"
                                  errorMessage="date_of_birth is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="date_of_birth"
                                />
                              </FormGroup>

                              <FormGroup>
                                <Label htmlFor="mobile">Phone</Label>
                                <PhoneInput
                                  country={"lu"}
                                  specialLabel={""}
                                  value={mobile}
                                  onChange={(e) => {
                                    handleMobile(e);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label htmlFor="preferred_languages">
                                  Which languages do you speak?
                                </Label>
                                <Select
                                  className="select-font"
                                  options={options}
                                  isMulti
                                  onChange={handleLanguage}
                                  name="preferred_languages"
                                  placeholder="Please select multiple languages"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="using_whatsapp_messenger">
                                  Do you use WhatsApp Messenger?
                                </Label>
                                <Input
                                  name="using_whatsapp_messenger"
                                  placeholder="Enter "
                                  value={using_whatsapp_messenger}
                                  onChange={handelInput}
                                  type="select"
                                  errorMessage="using_whatsapp_messenger is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="using_whatsapp_messenger"
                                >
                                  <option hidden>Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Input>
                              </FormGroup>
                              {using_whatsapp_messenger === "Yes" && (
                                <FormGroup>
                                  <Label htmlFor="another_whatsapp_mobile">
                                    If the number you use on WhatsApp is
                                    different please share that with us here
                                  </Label>
                                  <PhoneInput
                                    country={"lu"}
                                    specialLabel={""}
                                    value={another_whatsapp_mobile}
                                    onChange={(e) => {
                                      handleMobileWhatsapp(e);
                                    }}
                                  />
                                </FormGroup>
                              )}

                              <FormGroup>
                                <Label htmlFor="email">Email address</Label>
                                <Input
                                  name="email"
                                  placeholder="Enter Email"
                                  value={email}
                                  onChange={handelInput}
                                  type="email"
                                  errorMessage="email is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="email"
                                />
                              </FormGroup>

                              <FormGroup className="password-input">
                                <Label htmlFor="password">Password
                                <br/>
                                <p className="pass-hint">Password must contain: Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (can include #@$!%*?&)</p>
                                </Label>
                                <Input
                                  name="password"
                                  placeholder="Enter password"
                                  value={password}
                                  onChange={handelInput}
                                  type={passwordType}
                                  errorMessage="password is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="password"
                                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$"
                                  title="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (can include #@$!%*?&)"
                                />
                                <p
                                  className="password-icon1"
                                  onClick={viewPassword}
                                >
                                  {passwordType === "password" ? (
                                    <FaRegEye />
                                  ) : (
                                    <FaRegEyeSlash />
                                  )}
                                </p>
                              </FormGroup>

                              <FormGroup className="password-input">
                                <Label htmlFor="cnfPassword">
                                  Confirm Password
                                </Label>
                                <Input
                                  name="cnfPassword"
                                  placeholder=" Confirm Password"
                                  value={cnfPassword}
                                  onChange={handelInput}
                                  type={confirmPasswordType}
                                  errorMessage="cnfPassword is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="cnfPassword"
                                />
                                <p
                                  className="password-icon"
                                  onClick={viewConfirmPassword}
                                >
                                  {confirmPasswordType === "password" ? (
                                    <FaRegEye />
                                  ) : (
                                    <FaRegEyeSlash />
                                  )}
                                </p>
                                <p className="text-danger password-alert">
                                  {cnfPasswordMsg}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>
                      </TabPane>

                      <TabPane tabId={2}>
                        <div>
                          <Form>
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <Label htmlFor="street_name">
                                    Street Name and Number
                                  </Label>
                                  <Input
                                    name="street_name"
                                    placeholder="Enter Street Name"
                                    value={street_name}
                                    onChange={handelInput}
                                    type="text"
                                    errorMessage="street_name is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="street_name"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <Label htmlFor="city">
                                    Town/Village/City
                                  </Label>
                                  <Input
                                    name="city"
                                    placeholder="Enter City"
                                    value={city}
                                    onChange={handelInput}
                                    type="text"
                                    errorMessage="city is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="city"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <Label htmlFor="country">Country</Label>
                                  <Select
                                    className="select-font"
                                    options={countryOptions}
                                    value={countryValue}
                                    onChange={changeHandler}
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <Label htmlFor="postal_code">Post Code</Label>
                                  <Input
                                    name="postal_code"
                                    placeholder="Enter Postal Code"
                                    value={postal_code}
                                    onChange={handelInput}
                                    type="text"
                                    errorMessage="postal_code is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="postal_code"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>

                      <TabPane tabId={3}>
                        <div>
                          <Form>
                            <Row>
                              <Col lg="6" className="division">
                                <FormGroup>
                                  <Label htmlFor="reason_of_joining">
                                    Why do you want to join our network?
                                  </Label>
                                  <Input
                                    name="reason_of_joining"
                                    placeholder="Enter Reason"
                                    value={reason_of_joining}
                                    onChange={handelInput}
                                    type="textarea"
                                    errorMessage="reason_of_joining is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="reason_of_joining"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <Label htmlFor="have_your_own_dog">
                                    Do you have your own dog here in Luxembourg?
                                  </Label>
                                  <Input
                                    name="have_your_own_dog"
                                    placeholder="Enter "
                                    value={have_your_own_dog}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="have_your_own_dog is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="have_your_own_dog"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Input>
                                </FormGroup>
                                {have_your_own_dog === "Yes" && (
                                  <FormGroup>
                                    <Label htmlFor="sitter_dog_info">
                                      If YES then please tell us about your dog
                                      (Name, girl or boy? Neutered or not? what
                                      breed? how old he/she is? Is your dog good
                                      with other dogs? )
                                    </Label>
                                    <Input
                                      name="sitter_dog_info"
                                      placeholder="Enter Sitter Dog info"
                                      value={sitter_dog_info}
                                      onChange={handelInput}
                                      type="textarea"
                                      errorMessage="sitter_dog_info is required"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="sitter_dog_info"
                                    />
                                  </FormGroup>
                                )}

                                <FormGroup>
                                  <Label htmlFor="have_your_own_cat">
                                    Do you have a cat at home here in
                                    Luxembourg?
                                  </Label>
                                  <Input
                                    name="have_your_own_cat"
                                    placeholder="Enter have_your_own_cat"
                                    value={have_your_own_cat}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="have_your_own_cat is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="have_your_own_cat"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Input>
                                </FormGroup>

                                <FormGroup>
                                  <Label htmlFor="has_previous_experience_for_own_dog">
                                    Do you have previous experience caring for
                                    your own dog(s)?
                                  </Label>
                                  <Input
                                    name="has_previous_experience_for_own_dog"
                                    placeholder="Enter has_previous_experience_for_own_dog"
                                    value={has_previous_experience_for_own_dog}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="has_previous_experience_for_own_dog is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="has_previous_experience_for_own_dog"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Input>
                                </FormGroup>
                                {has_previous_experience_for_own_dog ===
                                  "Yes" && (
                                  <FormGroup>
                                    <Label htmlFor="own_dog_experience_details">
                                      If YES, please tell us about this
                                      experience in detail
                                    </Label>
                                    <Input
                                      name="own_dog_experience_details"
                                      placeholder="Enter Experience Detail"
                                      value={own_dog_experience_details}
                                      onChange={handelInput}
                                      type="textarea"
                                      errorMessage="own_dog_experience_details is required"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="own_dog_experience_details"
                                    />
                                  </FormGroup>
                                )}
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <Label htmlFor="has_previous_experience_as_dog_sitter">
                                    Do you have previous experience as a dog
                                    sitter for someone else?
                                  </Label>
                                  <Input
                                    name="has_previous_experience_as_dog_sitter"
                                    placeholder="Enter Previous Experience"
                                    value={
                                      has_previous_experience_as_dog_sitter
                                    }
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="has_previous_experience_as_dog_sitter is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="has_previous_experience_as_dog_sitter"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Input>
                                </FormGroup>
                                {has_previous_experience_as_dog_sitter ===
                                  "Yes" && (
                                  <FormGroup>
                                    <Label htmlFor="as_sitter_experience_details">
                                      If YES, please tell us about this
                                      experience in detail
                                    </Label>
                                    <Input
                                      name="as_sitter_experience_details"
                                      placeholder="Enter Sitter Experience"
                                      value={as_sitter_experience_details}
                                      onChange={handelInput}
                                      type="textarea"
                                      errorMessage="as_sitter_experience_details is required"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="as_sitter_experience_details"
                                    />
                                  </FormGroup>
                                )}

                                <FormGroup>
                                  <Label htmlFor="has_formal_training">
                                    Do you have any formal training related to
                                    dogs or other animals?
                                  </Label>
                                  <Input
                                    name="has_formal_training"
                                    placeholder="Enter has_formal_training"
                                    value={has_formal_training}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="has_formal_training is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="has_formal_training"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Input>
                                </FormGroup>
                                {has_formal_training === "Yes" && (
                                  <FormGroup>
                                    <Label htmlFor="formal_training_details">
                                      If YES, please tell us about this training
                                      in detail
                                    </Label>
                                    <Input
                                      name="formal_training_details"
                                      placeholder="Enter Formal Training"
                                      value={formal_training_details}
                                      onChange={handelInput}
                                      type="textarea"
                                      errorMessage="formal_training_details is required"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="formal_training_details"
                                    />
                                  </FormGroup>
                                )}

                                <FormGroup>
                                  <Label htmlFor="cared_for_other_than_dog">
                                    Have you cared for other types of animals
                                    (besides dogs)?
                                  </Label>
                                  <Input
                                    name="cared_for_other_than_dog"
                                    placeholder="Enter cared_for_other_than_dog"
                                    value={cared_for_other_than_dog}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="cared_for_other_than_dog is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="cared_for_other_than_dog"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Input>
                                </FormGroup>
                                {cared_for_other_than_dog === "Yes" && (
                                  <FormGroup>
                                    <Label htmlFor="caring_details_for_other_than_dog">
                                      If YES, please tell us about this
                                      experience in detail
                                    </Label>
                                    <Input
                                      name="caring_details_for_other_than_dog"
                                      placeholder="Enter Caring Details"
                                      value={caring_details_for_other_than_dog}
                                      onChange={handelInput}
                                      type="textarea"
                                      errorMessage="caring_details_for_other_than_dog is required"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="caring_details_for_other_than_dog"
                                    />
                                  </FormGroup>
                                )}
                                <FormGroup check className="my-3 text-center">
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      onChange={handleCheck}
                                      value={validateCheck}
                                    />
                                    I accept the DogStays
                                    <a
                                      className="terms"
                                      href="/terms_privacy"
                                      target="_blank"
                                    >
                                      {" "}
                                      Privacy Policy
                                    </a>
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>

                      {/* <TabPane tabId={4}>
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                <a href="https://gifyu.com/image/SKwYo">
                                  <img
                                    src="https://s8.gifyu.com/images/successful.gif"
                                    alt="successful.gif"
                                    border="0"
                                    style={{ width: "130px" }}
                                  />
                                </a>
                              </div>
                              <div>
                                <h5>Application Submitted Thank You!</h5>
                                <p className="text-muted">
                                  We will review the information you have shared
                                  with us and send you an email.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </TabPane> */}
                    </TabContent>

                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTab === 1 || activeTab === 4
                            ? "previous disabled d-none"
                            : "previous"
                        }
                      >
                        <DynamicButton
                          clickFunction={() => {
                            toggleTab(activeTab - 1);
                          }}
                          createBtnText="Previous"
                        />
                      </li>
                      <li className={activeTab === 4 ? "next d-none" : "next"}>
                        <DynamicButton
                          className={activeTab === 3 ? "disabled" : "none"}
                          clickFunction={(e) => {
                            if (activeTab === 1) {
                              if (
                                hear_from_source === "" ||
                                first_name === "" ||
                                last_name === "" ||
                                gender === "" ||
                                date_of_birth === "" ||
                                mob === "" ||
                                preferedLanguage.length === 0 ||
                                email === "" ||
                                password === "" ||
                                cnfPassword === "" ||
                                using_whatsapp_messenger === ""
                              ) {
                                toast.warning("Please fill all the fields", {
                                  position: "top-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: false,
                                  progress: undefined,
                                });
                              } else if (vaildPassword.test(password)) {
                                if (password !== cnfPassword) {
                                  setCnfPasswordMsg("Password Doesn`t Match");
                                } else {
                                  toggleTab(activeTab + 1);
                                  handleCnfPasswordAlert();
                                }
                              } else {
                                setIsPasswordAlertModal(true);
                              }
                            }

                            if (activeTab === 2) {
                              if (
                                street_name === "" ||
                                city === "" ||
                                countryValue === "" ||
                                postal_code === ""
                              ) {
                                toast.warning("Please fill all the fields", {
                                  position: "top-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: false,
                                  progress: undefined,
                                });
                              } else {
                                toggleTab(activeTab + 1);
                              }
                            }

                            if (activeTab === 3) {
                              if (
                                reason_of_joining === "" ||
                                have_your_own_dog === "" ||
                                have_your_own_cat === "" ||
                                has_previous_experience_for_own_dog === "" ||
                                has_previous_experience_as_dog_sitter === "" ||
                                has_formal_training === "" ||
                                cared_for_other_than_dog === ""
                              ) {
                                toast.warning("Please fill all the fields", {
                                  position: "top-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: false,
                                  progress: undefined,
                                });
                              } else if (validateCheck === false) {
                                setIsPrivacyAlertModal(true);
                              } else {
                                handleSubmit(e);
                              }
                            }
                          }}
                          createBtnText={activeTab === 3 ? "Submit" : "Next"}
                        />
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isAlertModal && (
          <div>
            <Modal size="lg" isOpen={isAlertModal} centered backdrop="static">
              <ModalBody>
                <h6 className="alert-msg">{alertMsg}</h6>
                <div className="col-12 text-center">
                  <DynamicButton
                    createBtnText="Close"
                    clickFunction={() => {
                      setIsAlertModal(false);
                    }}
                  />
                </div>
              </ModalBody>
            </Modal>
          </div>
        )}

        {isPasswordAlertModal && (
          <div>
            <Modal
              size="lg"
              isOpen={isPasswordAlertModal}
              centered
              backdrop="static"
            >
              <ModalBody>
                <h4 className="alert-msg"> Password Must Contain:</h4>
                <h6 className="alert-msg">
                  Minimum eight characters, at least one uppercase letter, one
                  lowercase letter, one number and one special character (can include #@$!%*?&)
                </h6>
                <div className="col-12 text-center">
                  <DynamicButton
                    createBtnText="Close"
                    clickFunction={() => {
                      setIsPasswordAlertModal(false);
                      toggleTab(activeTab - 1);
                    }}
                  />
                </div>
              </ModalBody>
            </Modal>
          </div>
        )}

        {isPrivacyAlertModal && (
          <div>
            <Modal
              size="lg"
              isOpen={isPrivacyAlertModal}
              centered
              backdrop="static"
            >
              <ModalBody>
                <h6 className="alert-msg text-center">
                  To Continue, Please Accept the DogStays Privacy Policy
                </h6>
                <div className="col-12 text-center">
                  <DynamicButton
                    createBtnText="Close"
                    clickFunction={() => {
                      setIsPrivacyAlertModal(false);
                    }}
                  />
                </div>
              </ModalBody>
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};

export default SitterForm;
