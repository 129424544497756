import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Form,
  InputGroup,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import ProfilePictureUploader from "../ProfilePicture";
import { FaRegEye, FaRegEyeSlash, FaPen, FaWindowClose } from "react-icons/fa";
import { DynamicButton } from "../../../../Components/DynamicButton";
import successImg from "../../../../Assests/Images/undraw_done_re_oak4.svg";

const languageOptions = [
  { value: "English", label: "English" },
  { value: "French", label: "French" },
  { value: "German", label: "German" },
  { value: "Luxembourgish", label: "Luxembourgish" },
  { value: "Spanish", label: "Spanish" },
  { value: "Italian", label: "Italian" },
  { value: "Portugese", label: "Portugese" },
  { value: "Dutch", label: "Dutch" },

  { value: "Akan", label: "Akan" },
  { value: "Amharic", label: "Amharic" },
  { value: "Arabic", label: "Arabic" },
  { value: "Assamese", label: "Assamese" },
  { value: "Awadhi", label: "Awadhi" },
  { value: "Azerbaijani", label: "Azerbaijani" },
  { value: "Balochi", label: "Balochi" },
  { value: "Belarusian", label: "Belarusian" },
  { value: "Bengali", label: "Bengali" },
  { value: "Bhojpuri", label: "Bhojpuri" },
  { value: "Burmese", label: "Burmese" },
  { value: "Cebuano (Visayan)", label: "Cebuano (Visayan)" },
  { value: "Chewa", label: "Chewa" },
  { value: "Chhattisgarhi", label: "Chhattisgarhi" },
  { value: "Chittagonian", label: "Chittagonian" },
  { value: "Czech", label: "Czech" },
  { value: "Deccan", label: "Deccan" },
  { value: "Dhundhari", label: "Dhundhari" },
  { value: "Eastern Min", label: "Eastern Min" },
  { value: "Fula", label: "Fula" },
  { value: "Gan Chinese", label: "Gan Chinese" },
  { value: "Greek", label: "Greek" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Haitian Creole", label: "Haitian Creole" },
  { value: "Hakka", label: "Hakka" },
  { value: "Haryanvi", label: "Haryanvi" },
  { value: "Hausa", label: "Hausa" },
  { value: "Hiligaynon", label: "Hiligaynon" },
  { value: "Hindi", label: "Hindi" },
  { value: "Hmong", label: "Hmong" },
  { value: "Hungarian", label: "Hungarian" },
  { value: "Igbo", label: "Igbo" },
  { value: "Ilocano", label: "Ilocano" },
  { value: "Italian", label: "Italian" },
  { value: "Japanese", label: "Japanese" },
  { value: "Javanese", label: "Javanese" },
  { value: "Jin", label: "Jin" },
  { value: "Kannada", label: "Kannada" },
  { value: "Kazakh", label: "Kazakh" },
  { value: "Khmer", label: "Khmer" },
  { value: "Kinyarwanda", label: "Kinyarwanda" },
  { value: "Kirundi", label: "Kirundi" },
  { value: "Konkani", label: "Konkani" },
  { value: "Korean", label: "Korean" },
  { value: "Kurdish", label: "Kurdish" },
  { value: "Madurese", label: "Madurese" },
  { value: "Magahi", label: "Magahi" },
  { value: "Maithili", label: "Maithili" },
  { value: "Malagasy", label: "Malagasy" },
  { value: "Malay/Indonesian", label: "Malay/Indonesian" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Mandarin", label: "Mandarin" },
  { value: "Marathi", label: "Marathi" },
  { value: "Marwari", label: "Marwari" },
  { value: "Mossi", label: "Mossi" },
  { value: "Nepali", label: "Nepali" },
  { value: "Northern Min", label: "Northern Min" },
  { value: "Odia (Oriya)", label: "Odia (Oriya)" },
  { value: "Oromo", label: "Oromo" },
  { value: "Pashto", label: "Pashto" },
  { value: "Persian", label: "Persian" },
  { value: "Polish", label: "Polish" },
  { value: "Punjabi", label: "Punjabi" },
  { value: "Quechua", label: "Quechua" },
  { value: "Romanian", label: "Romanian" },
  { value: "Russian", label: "Russian" },
  { value: "Saraiki", label: "Saraiki" },
  { value: "Serbo-Croatian", label: "Serbo-Croatian" },
  { value: "Shona", label: "Shona" },
  { value: "Sindhi", label: "Sindhi" },
  { value: "Sinhalese", label: "Sinhalese" },
  { value: "Somali", label: "Somali" },
  { value: "Southern Min", label: "Southern Min" },
  { value: "Sundanese", label: "Sundanese" },
  { value: "Swedish", label: "Swedish" },
  { value: "Sylheti", label: "Sylheti" },
  { value: "Tagalog", label: "Tagalog" },
  { value: "Tamil", label: "Tamil" },
  { value: "Telugu", label: "Telugu" },
  { value: "Thai", label: "Thai" },
  { value: "Turkish", label: "Turkish" },
  { value: "Turkmen", label: "Turkmen" },
  { value: "Ukrainian", label: "Ukrainian" },
  { value: "Urdu", label: "Urdu" },
  { value: "Uyghur", label: "Uyghur" },
  { value: "Uzbek", label: "Uzbek" },
  { value: "Vietnamese", label: "Vietnamese" },
  { value: "Wu (inc. Shanghainese)", label: "Wu (inc. Shanghainese)" },
  { value: "Xhosa", label: "Xhosa" },
  { value: "Xiang (Hunnanese)", label: "Xiang (Hunnanese)" },
  { value: "Yoruba", label: "Yoruba" },
  { value: "Yue (Cantonese)", label: "Yue (Cantonese)" },
  { value: "Zhuang", label: "Zhuang" },
  { value: "Zulu", label: "Zulu" },
];

const formatGetMultiSelect = (arr) => {
  const res = arr?.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
  return res;
};

const formatPostMultiSelect = (arr) => {
  const res = arr?.map(({ value }) => value);
  return res;
};

const BasicInfo = ({ data, updateData }) => {
  const {
    first_name,
    last_name,
    gender,
    date_of_birth,
    mobile,
    preferred_languages,
    using_whatsapp_messenger,
    another_whatsapp_mobile,
    email,
    passport_image,
    photo,
  } = data || {};

  const initialFormData = {
    first_name: first_name || "",
    last_name: last_name || "",
    gender: gender || "",
    date_of_birth: date_of_birth || "",
    mobile: mobile || "",
    preferred_languages: formatGetMultiSelect(preferred_languages) || [],
    using_whatsapp_messenger: using_whatsapp_messenger ? "Yes" : "No",
    another_whatsapp_mobile: another_whatsapp_mobile || "",
    email: email || "",
    password: "",
    cnfPassword: "",
    cnfPasswordMsg: "",
    passwordType: "password",
    confirmPasswordType: "password",
  };

  const [formData, setFormData] = useState({});
  const [validationMessage, setValidationMessage] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [isEditingPassword, setEditPassword] = useState(false);
  const [imgURL, setImageURL] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [imageUrl2, setImageUrl2] = useState(null);
  const [isEditDocument, setIsEditDocument] = useState(false);
  const [isUploadAlertModal, setIsUploadAlertModal] = useState(false);
  const pattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/;

  useEffect(() => {
    if (data) {
      setFormData(initialFormData);
      setImageUrl2(passport_image);
    }
  }, [data]);

  useEffect(() => {
    if (!editMode) {
      setFormData(initialFormData);
      setIsEditDocument(false);
    }
  }, [editMode]);

  useEffect(() => {
    if(isEditDocument){
      setImageUrl2("");
    }
    else{
      setImageUrl2(passport_image);
    }
  },[isEditDocument])

  const handleInputChange = (e) => {
    if (editMode || isEditingPassword) {
      // Handle input changes when in edit mode
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleMobileChange = (value) => {
    if (editMode) {
      setFormData({
        ...formData,
        mobile: value,
      });
    }
  };

  const handleMobileWhatsappChange = (value) => {
    if (editMode) {
      setFormData({
        ...formData,
        another_whatsapp_mobile: value,
      });
    }
  };

  const handleLanguageChange = (selectedOptions) => {
    if (editMode) {
      //const languages = selectedOptions.map((option) => option.value);
      setFormData({
        ...formData,
        preferred_languages: selectedOptions,
      });
    }
  };

  const togglePasswordVisibility = (fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formData[fieldName] === "password" ? "text" : "password",
    });
  };

  const setProfilePicUrl = (url) => {
    setImageURL(url);
  };

  const handleSave = async () => {
    // Define an array to store validation errors
    const validationErrors = [];

    // Check if any of the required fields are empty
    if (!formData.first_name) {
      validationErrors["first_name"] = "Name is required.";
    }
    if (!formData.last_name) {
      validationErrors["last_name"] = "Family Name is required.";
    }
    if (!formData.gender) {
      validationErrors["gender"] = "Gender is required.";
    }
    if (!formData.date_of_birth) {
      validationErrors["date_of_birth"] = "Date of Birth is required.";
    }
    if (!formData.mobile) {
      validationErrors["mobile"] = "Phone Number is required.";
    }
    if (
      formData.using_whatsapp_messenger === "Yes" &&
      !formData.another_whatsapp_mobile
    ) {
      validationErrors["another_whatsapp_mobile"] =
        "WhatsApp Number is required if you use WhatsApp.";
    }
    if (formData.preferred_languages.length === 0) {
      validationErrors["preferred_languages"] =
        "Please select at least one preferred language.";
    }
    if (isEditingPassword) {
      if (!formData.password) {
        validationErrors["password"] = "Please Enter Password.";
      }
      if (!pattern.test(formData.password)) {
        validationErrors["password"] = "Password doesn't fit the requirements";
      }
      if (formData.password !== formData.cnfPassword) {
        validationErrors["password"] =
          "Password and Confirm Password do not match.";
      }
    }
    if (!imageUrl2) {
      validationErrors["passport_image"] = "Please Upload ID Proof.";
    }

    // Check if there are any validation errors
    if (Object.keys(validationErrors).length > 0) {
      setValidationMessage(validationErrors);
    } else {
      // If there are no validation errors, proceed with form submission
      setValidationMessage("");
      const payload = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        gender: formData.gender,
        date_of_birth: formData.date_of_birth,
        mobile: formData.mobile,
        preferred_languages: formatPostMultiSelect(
          formData.preferred_languages
        ),
        using_whatsapp_messenger: formData.using_whatsapp_messenger === "Yes",
        another_whatsapp_mobile: formData.another_whatsapp_mobile,
        ...(formData.password ? { password: formData.password } : {}),
        ...(imgURL ? { photo: imgURL } : {}),
        ...(imageUrl2 ? { passport_image: imageUrl2 } : {}),
      };
      await updateData(payload);
      setEditMode(false);
      setIsUploading(false);
      setEditPassword(false);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: editMode ? "#f9eae3" : provided.backgroundColor,
    }),
    multiValueRemove: (base) => {
      return !editMode ? { ...base, display: "none" } : { ...base };
    },
  };

  const UploadImage2 = async (image) => {
    return await axios
      .post("https://api.dogstays.lu/basic/upload/image", image)
      .then((res) => {
        setImageUrl2(res.data.data.url);
        setIsUploading(true);
      })
      .catch((err) => console.log(err));
  };

  const handleImage2 = (e) => {
    if (editMode) {
      const uploadFile = e.target.files[0];
      const fileSize = e.target.files[0].size;
      const imageFormData = new FormData();
      imageFormData.append("image", uploadFile);
      fileSize < "5000000"
        ? UploadImage2(imageFormData)
        : setIsUploadAlertModal(true);
    }
  };

  return (
    <>
      <Form>
        <Row>
          <Col md="12">
            <div style={{ float: "right", marginRight: "10px" }}>
              {editMode ? (
                <FaWindowClose
                  onClick={() => {
                    setEditMode(false);
                    setValidationMessage("");
                    setImageUrl2(passport_image);
                  }}
                />
              ) : (
                <FaPen
                  onClick={() => {
                    setEditMode(true);
                    setValidationMessage("");
                  }}
                />
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <ProfilePictureUploader
              isEditAllowed={editMode}
              profilePic={photo}
              setProfilePicUrl={setProfilePicUrl}
            />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="first_name">Name</Label>
              <Input
                name="first_name"
                placeholder="Enter Name"
                value={formData.first_name}
                onChange={handleInputChange}
                type="text"
                className="form-control"
                id="first_name"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              />
              {validationMessage.first_name && (
                <div style={{ color: "red" }}>
                  {validationMessage.first_name}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <Label htmlFor="last_name">Family Name</Label>
              <Input
                name="last_name"
                placeholder="Enter Family Name"
                value={formData.last_name}
                onChange={handleInputChange}
                type="text"
                className="form-control"
                id="last_name"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              />
              {validationMessage.last_name && (
                <div style={{ color: "red" }}>
                  {validationMessage.last_name}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="gender">Gender</Label>
              <Input
                name="gender"
                placeholder="Enter gender"
                value={formData.gender}
                onChange={handleInputChange}
                type="select"
                className="form-control"
                id="gender"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              >
                <option hidden>Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Input>
              {validationMessage.gender && (
                <div style={{ color: "red" }}>{validationMessage.gender}</div>
              )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="date_of_birth">Date of birth</Label>
              <Input
                name="date_of_birth"
                placeholder="Enter DOB"
                value={formData.date_of_birth}
                onChange={handleInputChange}
                max={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 15)
                  )
                    .toISOString()
                    .split("T")[0]
                }
                type="date"
                className="form-control"
                id="date_of_birth"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              />
              {validationMessage.date_of_birth && (
                <div style={{ color: "red" }}>
                  {validationMessage.date_of_birth}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="mobile">Phone</Label>
              <PhoneInput
                country={"lu"}
                specialLabel={""}
                value={formData.mobile}
                onChange={handleMobileChange}
                disabled={!editMode}
                inputStyle={{
                  backgroundColor: `${editMode ? "#f9eae3" : "#ebecec"}`,
                }}
              />
              {validationMessage.mobile && (
                <div style={{ color: "red" }}>{validationMessage.mobile}</div>
              )}
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <Label htmlFor="email">Email address</Label>
              <Input
                name="email"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleInputChange}
                disabled={true}
                type="email"
                className="form-control"
                id="email"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="using_whatsapp_messenger">
                Do you use WhatsApp Messenger?
              </Label>
              <Input
                name="using_whatsapp_messenger"
                placeholder="Enter "
                value={formData.using_whatsapp_messenger}
                onChange={handleInputChange}
                type="select"
                className="form-control"
                id="using_whatsapp_messenger"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
              {validationMessage.using_whatsapp_messenger && (
                <div style={{ color: "red" }}>
                  {validationMessage.using_whatsapp_messenger}
                </div>
              )}
            </FormGroup>
          </Col>
          {formData.using_whatsapp_messenger === "Yes" && (
            <Col md="6">
              <FormGroup>
                <Label htmlFor="another_whatsapp_mobile">
                  Your Number on WhatsApp
                </Label>
                <PhoneInput
                  country={"lu"}
                  specialLabel={""}
                  value={formData.another_whatsapp_mobile}
                  onChange={handleMobileWhatsappChange}
                  disabled={!editMode}
                  inputStyle={{
                    backgroundColor: `${editMode ? "#f9eae3" : "#ebecec"}`,
                  }}
                />
                {validationMessage.another_whatsapp_mobile && (
                  <div style={{ color: "red" }}>
                    {validationMessage.another_whatsapp_mobile}
                  </div>
                )}
              </FormGroup>
            </Col>
          )}
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="preferred_languages">
                Which languages do you speak?
              </Label>
              <Select
                className="select-font"
                options={languageOptions}
                isMulti
                onChange={handleLanguageChange}
                name="preferred_languages"
                value={formData.preferred_languages}
                placeholder="Please select multiple languages"
                isDisabled={!editMode}
                styles={customStyles}
              />
              {validationMessage.preferred_languages && (
                <div style={{ color: "red" }}>
                  {validationMessage.preferred_languages}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup className="text-center">
              <Label htmlFor="comfortable_with_cat_or_other">
                Identification Document (EU ID/Passport)
              </Label>
              {isEditDocument ? (
                <div>
                  <Input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf,.webp,.doc"
                    onChange={(e) => handleImage2(e)}
                  />
                  <a
                    className="btn doc-view-btn ms-2"
                    style={{margin: '10px'}}
                    onClick={() => {
                      setIsEditDocument(false);
                      setValidationMessage("");
                      setIsUploading(false);
                      setImageUrl2(passport_image)                  
                    }}
                  >
                    Cancel
                  </a>
                  {isUploading && (
                    <h4 className="mt-4">
                      Document Uploaded:{" "}
                      <img
                        src={successImg}
                        alt="successful.gif"
                        border="0"
                        style={{ width: "33px" }}
                      />
                    </h4>
                  )}
                </div>
              ) : (
                imageUrl2 && (
                  <div className="mt-3">
                    <a
                      className="btn btn-primary doc-view-btn"
                      href={imageUrl2}
                      target="_blank"
                    >
                      View Document
                    </a>
                    {editMode && (
                      <a
                        style={{ marginLeft: "5%" }}
                        className="btn doc-view-btn"
                        onClick={() => {
                          setIsEditDocument(true);
                          setImageUrl2("");
                        }}
                      >
                        Edit Document
                      </a>
                    )}
                  </div>
                )
              )}
              {validationMessage.passport_image && (
                <div style={{ color: "red" }}>
                  {validationMessage.passport_image}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        {editMode && (
          <Row>
            <Col md="6">
              <FormGroup>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Update
                </button>
              </FormGroup>
            </Col>
          </Row>
        )}
      </Form>
      <Row>
        <Card>
          <CardBody>
            <CardTitle>
              <span
                style={{ fontSize: "1.5rem", borderBottom: "1px solid #000" }}
              >
                Change My Password
              </span>

              <div style={{ float: "right", marginRight: "10px" }}>
                {isEditingPassword ? (
                  <FaWindowClose onClick={() => setEditPassword(false)} />
                ) : (
                  <FaPen onClick={() => setEditPassword(true)} />
                )}
              </div>
            </CardTitle>

            <Form>
              <Row>
                <Col md="12">
                  <p className="pass-hint">
                    Password must contain: Minimum eight characters, at least
                    one uppercase letter, one lowercase letter, one number and
                    one special character (can include #@$!%*?&)
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup className="password-input">
                    <Label htmlFor="password">Password</Label>
                    <InputGroup>
                      <Input
                        name="password"
                        placeholder="Enter password"
                        value={formData.password}
                        onChange={handleInputChange}
                        type={formData.passwordType}
                        disabled={!isEditingPassword}
                        style={{
                          backgroundColor: `${
                            isEditingPassword ? "#f9eae3" : ""
                          }`,
                        }}
                        className="form-control"
                        id="password"
                        pattern={pattern}
                        title="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character (can include #@$!%*?&)"
                      />
                      <Button
                        onClick={() => togglePasswordVisibility("passwordType")}
                      >
                        {formData.passwordType === "password" ? (
                          <FaRegEye />
                        ) : (
                          <FaRegEyeSlash />
                        )}
                      </Button>
                    </InputGroup>
                    {validationMessage.password && (
                      <div style={{ color: "red" }}>
                        {validationMessage.password}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="cnfPassword">Confirm Password</Label>
                    <InputGroup>
                      <Input
                        name="cnfPassword"
                        placeholder="Confirm Password"
                        value={formData.cnfPassword}
                        onChange={handleInputChange}
                        type={formData.confirmPasswordType}
                        className="form-control"
                        id="cnfPassword"
                        disabled={!isEditingPassword}
                        style={{
                          backgroundColor: `${
                            isEditingPassword ? "#f9eae3" : ""
                          }`,
                        }}
                      />
                      <Button
                        onClick={() =>
                          togglePasswordVisibility("confirmPasswordType")
                        }
                      >
                        {formData.confirmPasswordType === "password" ? (
                          <FaRegEye />
                        ) : (
                          <FaRegEyeSlash />
                        )}
                      </Button>
                    </InputGroup>
                    <p className="text-danger password-alert">
                      {formData.cnfPasswordMsg}
                    </p>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {isEditingPassword && (
              <Row>
                <Col md="6">
                  <FormGroup>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSave}
                    >
                      Update Password
                    </button>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Row>
      {isUploadAlertModal && (
        <div>
          <Modal
            size="lg"
            isOpen={isUploadAlertModal}
            centered
            backdrop="static"
          >
            <ModalBody>
              <h4 className="alert-msg text-center">
                This file can't be uploaded
              </h4>
              <h6 className="alert-msg text-center">
                Only Max size upto 5Mb can be uploaded
              </h6>
              <h6 className="alert-msg text-center">
                Click on choose file to select image again
              </h6>
              <div className="col-12 text-center">
                <DynamicButton
                  createBtnText="Close"
                  clickFunction={() => {
                    setIsUploadAlertModal(false);
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
};

export default BasicInfo;
