import { Button } from "reactstrap";
import "./style.css"

export const DynamicButton = ({
  clickFunction,
  type,
  isDisabledBtn,
  createBtnText,
  style
}) => {
  return (
    <Button className="dynamic-btn" style={style} onClick={clickFunction} type={type} disabled={isDisabledBtn}>
      {createBtnText} 
    </Button>
  );
};
