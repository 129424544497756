import React from "react";
import { Container, Row, Col } from "reactstrap";
import { assests } from "../../../../Assests/assets";
import { useNavigate } from "react-router-dom";
import "./style.css";
import NavbarComp from "../../../../Components/Navbar";

function Home() {
  const { BookingCalendar, PersonalInfo } = assests;
  const navigate = useNavigate();

  return (
    <div className="home">
    <Container>
      <div className="navbar">
        <NavbarComp />
      </div>
      </Container>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col md={4} xs={12} className="custom-col">
            <div
              onClick={() => navigate("/sitter/calendar")}
              className="newhome icon-container reduce-gap"
            >
              <img src={BookingCalendar} alt="Icon 1" className="custom-icon" />
              <h4 className="title">Your Calendar</h4>
            </div>
          </Col>
          <Col md={4} xs={12} className="custom-col">
            <div
              onClick={() => navigate("/sitter/personal_information")}
              className="icon-container reduce-gap"
            >
              <img src={PersonalInfo} alt="Icon 2" className="custom-icon" />
              <h4 className="title">Your Profile</h4>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
