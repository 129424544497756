import { Card, CardBody, Col, Container, Row } from "reactstrap"
import successImg from '../../../../Assests/Images/undraw_done_re_oak4.svg'

const ThanksForm2 = () => {
    return(
        <>
         <div className="thanks-contain">
                <Container fluid={false} className="">
                    <Row style={{ padding: "20px 0px" }}>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                <h2 className="Form-title mb-4 text-center">
                                    Sitter Booking Details Form
                                </h2>
                                    <div className="row justify-content-center">
                                        <Col lg="6">
                                            <div className="text-center">
                                                <div className="mb-4">
                                                    <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                                    <a>
                                                        <img
                                                            src={successImg}
                                                            alt="successful.gif"
                                                            border="0"
                                                            style={{ width: "130px" }}
                                                        />
                                                    </a>
                                                </div>
                                                <div>
                                                    <h5>Application Submitted Thank You!</h5>
                                                    <p className="text-muted mb-2">
                                                        We will review the information you have shared
                                                        with us and send you an email.
                                                    </p>
                                                    <h6 style={{color:'#fe7648'}}>
                                                        Please check your spam folder regularly to make sure you don't 
                                                        miss our email!
                                                    </h6>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ThanksForm2