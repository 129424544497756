
import Authentication from "../../Authentication";
import SitterForm from "../Views/FormApplication";
import StartUserApplication from "../Views/StartApplication/Views";
import SitterKycForm from "../Views/FormApplicationStep2";
import Home from "../Views/Home";
import Calendar from "../Views/Calendar";
import PersonalInformation from '../Views/PersonalInformation';
import PrivacyAndTerms from "../Views/Privacy&Tc";
import ResetPassword from "../Views/ResetPassword";
import ThanksForm1 from "../Views/ThankYouForm1";
import ThanksForm2 from "../Views/ThankYouForm2";
import BookingInformation from "../Views/BookingInfo";


export const UsersRoutes = [
    // {
    //   Component: Authentication,
    //   path: '/sitter/login'
    // },
    {
      Component: SitterKycForm,
      path: '/bookingpreferences'
    },
    {
      Component: Home,
      path: '/sitter/home'
    },
    {
      Component: Calendar,
      path: '/sitter/calendar'
    },
    {
      Component: PersonalInformation,
      path: '/sitter/personal_information'
    },
    {
      Component: PrivacyAndTerms,
      path: '/terms_privacy'
    },
    {
      Component: ResetPassword,
      path: '/reset_password'
    },
    {
      Component: ThanksForm1,
      path: '/sitterformsubmit'
    },{
      Component: ThanksForm2,
      path: '/bookdetailsubmit'
    },
    // {
    //   Component: BookingInformation,
    //   path: '/booking/information/:bookingId'
    // }
  ]

  export const otherRoutes = [
    {
      Component: StartUserApplication,
      path: '/applicationstart',
      exact: true,
      id: 'applicationstart',
      routePath: '/applicationstart',
    },
    {
      Component: SitterForm,
      path: '/applicationform',
      exact: true,
      id: 'applicationform',
      routePath: '/applicationform',
    },
    {
      Component: Authentication,
      path: '/login'
    },
    {
      Component: BookingInformation,
      path: '/booking/information/:bookingId'
    }
  ]