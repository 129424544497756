import React, { useState, useRef, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import { FaPen } from "react-icons/fa";
import { DynamicButton } from "../../../../Components/DynamicButton";
import { assests } from "../../../../Assests/assets";
import { Modal, ModalBody } from "reactstrap";
import axios from "axios";

const ProfilePictureUploader = ({
  isEditAllowed = false,
  profilePic,
  setProfilePicUrl,
}) => {
  const [image, setImage] = useState(null);
  const [isUploadAlertModal, setIsUploadAlertModal] = useState(false);
  const editorRef = useRef();
  const fileInputRef = useRef();
  const { No_DP } = assests;

  useEffect(() => {
    if (profilePic) {
      setImage(profilePic);
    }
  }, [profilePic]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImage(file);
      const fileSize = file.size;
      const imageFormData = new FormData();
      imageFormData.append("image", file);

      if (fileSize < 5000000) {
        uploadImage(imageFormData);
      } else {
        setIsUploadAlertModal(true); // You should define and use setIsUploadAlertModal according to your logic
      }
    }
  };

  const uploadImage = async (imageData) => {
    try {
      const response = await axios.post(
        "https://api.dogstays.lu/basic/upload/image",
        imageData
      );
      setProfilePicUrl(response.data.data.url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="profile-picture-container">
        {image ? (
          <AvatarEditor
            ref={editorRef}
            image={image}
            width={150}
            height={150}
            border={0}
            scale={1}
            rotate={0}
            borderRadius={150}
            color={[255, 255, 255, 1]}
          />
        ) : isEditAllowed ? (
          <input type="file" accept="image/*" onChange={handleImageChange} />
        ) : (
          <AvatarEditor
            image={No_DP}
            width={150}
            height={150}
            border={0}
            scale={1}
            rotate={0}
            borderRadius={150}
            color={[255, 255, 255, 1]}
          />
        )}

        {isEditAllowed && (
          <label htmlFor="fileInput" style={{ position: "absolute" }}>
            <FaPen />
          </label>
        )}
        <input
          type="file"
          id="fileInput"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleImageChange}
        />
      </div>
      {isUploadAlertModal && (
        <div>
          <Modal
            size="lg"
            isOpen={isUploadAlertModal}
            centered
            backdrop="static"
          >
            <ModalBody>
              <h4 className="alert-msg text-center">
                This file can't be uploaded
              </h4>
              <h6 className="alert-msg text-center">
                Only Max size upto 5Mb can be uploaded
              </h6>
              <h6 className="alert-msg text-center">
                Click on choose file to select image again
              </h6>
              <div className="col-12 text-center">
                <DynamicButton
                  createBtnText="Close"
                  clickFunction={() => {
                    setIsUploadAlertModal(false);
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ProfilePictureUploader;
