import dogstaysLogo from './Images/dogStaysLogo.png';
import bulldogBackground from './Images/section1background.jpg'
import Luxembourg from './Images/Luxembourg.webp'
import insta from './Images/insta.webp'
import fb from './Images/fb.webp'
import BookingCalendar from './Images/Calendar.png';
import PersonalInfo from './Images/Personal_information.png'
import No_DP from './Images/no_dp.png'
import dog from './Images/dog.png'
import dogInfo from './Images/dogInfo.png'
import careForm from './Images/careForm.png'

export const assests = {
    dogstaysLogo: dogstaysLogo,
    bulldogBackground: bulldogBackground,
    Luxembourg: Luxembourg,
    insta: insta,
    fb: fb,
    BookingCalendar,
    PersonalInfo,
    No_DP,
    dog,
    dogInfo,
    careForm
}