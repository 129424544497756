import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup, Input, Label, Row, Modal, ModalBody } from "reactstrap";
import { DynamicButton } from "../../../../Components/DynamicButton";
import { confirmResetPassword } from "../../../Authentication/Auth.apis";
import successImg from '../../../../Assests/Images/success.svg'

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmPasswordAlert, setConfirmPasswordAlert] = useState("");
  const [isPasswordAlertModal, setIsPasswordAlertModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0)
  const params = new URLSearchParams(window.location.search);
  const signature = params.get("signature");
  const email = params.get("email");
  const admin = params.get('admin')

  const navigate = useNavigate()

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const vaildPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/;

  const handleConfirmResetPasswordReq = async () => {
    if (newPassword === "" || confirmNewPassword === "") {
      toast.warning("Please Fill the Empty Field", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else if(vaildPassword.test(newPassword) === false){
      setIsPasswordAlertModal(true)
    }else if(newPassword !== confirmNewPassword){
      setConfirmPasswordAlert('Password Doesn`t Match')
    }
    else {
      const { data } = await confirmResetPassword({
        email: email,
        password: confirmNewPassword,
        signature: signature,
        admin: admin
      });
      if (data.response_code === 90) {
        toast.error(data.developer_message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
      if (data.response_code === 80) {
        setActiveTab(1)
      }
    }
  };
  
 
  return (
    <>
      <div className="container-fluid">
        <Row
          className="justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div
            className="px-5"
            style={{
              backgroundColor: "#f4ece1",
              width: "500px",
              height: "350px",
              borderRadius: "30px",
            }}
          >
            {activeTab == 0 && <><h1 className="text-center mt-4">Reset Password</h1>
            <FormGroup>
              <Label htmlFor="newPassword">New Password</Label>
              <Input
                name="newPassword"
                placeholder="Enter Password"
                value={newPassword}
                onChange={handleNewPassword}
                type="text"
                errorMessage="newPassword is required"
                className="form-control"
                id="newPassword"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="confirmNewPassword">Confirm Password</Label>
              <Input
                name="confirmNewPassword"
                placeholder="Enter Password"
                value={confirmNewPassword}
                onChange={handleConfirmNewPassword}
                type="text"
                errorMessage="confirmNewPassword is required"
                className="form-control"
                id="confirmNewPassword"
              />
              <p className="m-0 text-danger">{confirmPasswordAlert}</p>
            </FormGroup>
            <div className="text-center">
              <DynamicButton
                clickFunction={handleConfirmResetPasswordReq}
                createBtnText="Submit"
              />
            </div></>}
            {activeTab == 1 && <div className="row justify-content-center">
              <h3 className="text-center mt-4">Success!</h3>
              <img width={150} height={150} src={successImg}/>
              <h5 className="text-center mt-4">Password Updated</h5>
              <DynamicButton
                clickFunction={() => navigate('/')}
                createBtnText="Home"
              />
            </div>}
          </div>
        </Row>
      </div>

      {isPasswordAlertModal && (
        <div>
          <Modal
            size="lg"
            isOpen={isPasswordAlertModal}
            centered
            backdrop="static"
          >
            <ModalBody>
              <h4 className="alert-msg"> Password Must Contain:</h4>
              <h6 className="alert-msg">
                Minimum eight characters, at least one uppercase letter, one
                lowercase letter, one number and one special character (can include #@$!%*?&)
              </h6>
              <div className="col-12 text-center">
                <DynamicButton
                  createBtnText="Close"
                  clickFunction={() => {
                    setIsPasswordAlertModal(false);
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
