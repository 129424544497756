import axios from "axios";
import { getLocal } from "../../../../Utils/localStorage";
import { baseDomain, getsitterkfcForm, updatesitterkfcForm } from "../../../../Constants/apiRoutes";

const getSitterFormData = () => {
  const token = getLocal("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return axios.get(
    `${baseDomain}${getsitterkfcForm}`,
    config
  );
};

export const updateSitterFormData = (data) => {
  const token = getLocal("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  return axios.put(`${baseDomain}${updatesitterkfcForm}`, data, config);
};

export const fetchData = async () => {
    try {
      const res = await getSitterFormData();
      return res.data.data;
    } catch (err) {
      throw err;
    }
  };
