import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Form,
  Modal,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { DynamicButton } from "../../../../Components/DynamicButton";
import countryList from "react-select-country-list";
import Select from "react-select";
import successImg from "../../../../Assests/Images/undraw_done_re_oak4.svg";
import { FaPen, FaWindowClose } from "react-icons/fa";

const outdoor_spaces_options = [
  { value: "Terrace", label: "Terrace" },
  { value: "Garden", label: "Garden" },
  { value: "Balcony", label: "Balcony" },
  { value: "Yard", label: "Yard" },
  { value: "No green space", label: "None" },
];

const nearby_places_options = [
  { value: "Park", label: "Park" },
  { value: "Forest", label: "Forest" },
  { value: "Hiking trail", label: "Hiking trail" },
  { value: "Dog Park", label: "Dog Park" },
  { value: "Walking Path", label: "Walking Path" },
  { value: "No green space", label: "None" },
];

const formatGetMultiSelect = (arr) => {
  const res = arr?.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
  return res;
};

const formatPostMultiSelect = (arr) => {
  const res = arr?.map(({ value }) => value);
  return res;
};

const formatPostCountry = (arr) => {
  return arr.label;
};

const HomeSchedule = ({ data, updateData }) => {
  const {
    street_name,
    city,
    country,
    postal_code,
    home_type,
    home_size,
    living_situation,
    //living_situation_details,
    home_has_elevator,
    floor,
    outdoor_spaces,
    nearby_places,
    family_details,
    have_children,
    children_details,
    have_your_own_dog,
    sitter_dog_info,
    have_your_own_cat,
    has_driving_license,
    has_car,
    dog_emergency_details,
    daily_schedule,
    daily_schedule_details,
    address_proof_image,
  } = data || {};
  const initialFormData = {
    street_name: street_name || "",
    city: city || "",
    country: country || "",
    postal_code: postal_code || "",
    home_type: home_type || "",
    home_size: home_size || "",
    living_situation: living_situation || "",
    living_situation_details: "",
    home_has_elevator: home_has_elevator || "",
    floor: floor || "",
    outdoor_spaces: formatGetMultiSelect(outdoor_spaces) || [],
    nearby_places: formatGetMultiSelect(nearby_places) || [],
    family_details: family_details || "",
    have_children: have_children ? "Yes" : "No",
    children_details: children_details || "",
    have_your_own_dog: have_your_own_dog ? "Yes" : "No",
    sitter_dog_info: sitter_dog_info || "",
    have_your_own_cat: have_your_own_cat ? "Yes" : "No",
    has_driving_license: has_driving_license ? "Yes" : "No",
    has_car: has_car ? "Yes" : "No",
    dog_emergency_details: dog_emergency_details || "",
    daily_schedule: daily_schedule || "",
    daily_schedule_details: daily_schedule_details || "",
  };

  const resetFormData = {
    street_name: "",
    city: "",
    country: { value: "", label: "" },
    postal_code: "",
    home_type: "",
    home_size: "",
    living_situation: "",
    living_situation_details: "",
    home_has_elevator: "",
    floor: "",
    outdoor_spaces: [],
    nearby_places: [],
    family_details: "",
    have_children: "",
    children_details: "",
    have_your_own_dog: "",
    sitter_dog_info: "",
    have_your_own_cat: "",
    has_driving_license: "",
    has_car: "",
    dog_emergency_details: "",
    daily_schedule: "",
    daily_schedule_details: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [validationMessage, setValidationMessage] = useState("");
  const [isUploadAlertModal, setIsUploadAlertModal] = useState(false);
  const [formCountry, setFormCountry] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [imageUrl2, setImageUrl2] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isEditDocument, setIsEditDocument] = useState(false);
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  const countryOptions = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    const res = countryOptions?.find(({ label }) => country === label);
    setFormCountry(res);
  }, [country]);

  useEffect(() => {
    if (data) {
      setFormData(initialFormData);
      setImageUrl2(address_proof_image);
    }
  }, [data]);

  useEffect(() => {
    if (!editMode) {
      setFormData(initialFormData);
      setIsEditDocument(false);
    }
  }, [editMode]);

  const UploadImage2 = async (image) => {
    return await axios
      .post("https://api.dogstays.lu/basic/upload/image", image)
      .then((res) => {
        setImageUrl2(res.data.data.url);
        setIsUploading(true);
      })
      .catch((err) => console.log(err));
  };

  const handleImage2 = (e) => {
    if (editMode) {
      const uploadFile = e.target.files[0];
      const fileSize = e.target.files[0].size;
      const imageFormData = new FormData();
      imageFormData.append("image", uploadFile);
      fileSize < "5000000"
        ? UploadImage2(imageFormData)
        : setIsUploadAlertModal(true);
    }
  };

  const handleInputChange = (e) => {
    if (editMode) {
      // Handle input changes when in edit mode
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleCountryChange = (value) => {
    if (editMode) {
      setFormCountry(value);
    }
  };

  const handleOutdoorSpaces = (selectedOptions) => {
    if (editMode) {
      //const outdoor_spaces = selectedOptions.map((option) => option.value);
      setFormData({
        ...formData,
        outdoor_spaces: selectedOptions,
      });
    }
  };

  const handleNearbyPlace = (selectedOptions) => {
    if (editMode) {
      //const nearby_places = selectedOptions.map((option) => option.value);
      setFormData({
        ...formData,
        nearby_places: selectedOptions,
      });
    }
  };

  const handleAddressChange = () => {
    setAddressModalOpen(true);
  };

  const handleAddressChangeConfirm = (confirmed) => {
    if (confirmed) {
      setIsEditingAddress(true);
      setEditMode(true);
      setIsEditDocument(true);
      setFormData(resetFormData);
      setImageUrl2("");
      setFormCountry({ value: "", label: "" });
    }
    setAddressModalOpen(false);
  };

  const handleAddressChangeCancel = () => {
    setIsEditingAddress(false);
    setIsUploading(false);
    setFormData(initialFormData);
    setEditMode(false);
    const res = countryOptions?.find(({ label }) => country === label);
    setFormCountry(res);
    setImageUrl2(address_proof_image);
    setValidationMessage("");
  };

  const handleSave = async () => {
    // Define an object to store validation errors against field names
    const validationErrors = {};

    // Check if any of the required fields are empty
    if (!formData.street_name) {
      validationErrors["street_name"] = "Street Name is required.";
    }
    if (!formData.city) {
      validationErrors["city"] = "Town/Village/City Name is required.";
    }
    if (!formCountry.value) {
      validationErrors["country"] = "Country is required.";
    }
    if (!formData.postal_code) {
      validationErrors["postal_code"] = "Postal Code is required.";
    }
    if (!formData.home_type) {
      validationErrors["home_type"] = "Home Type is required.";
    }
    if (!formData.home_size) {
      validationErrors["home_size"] = "Home Size is required.";
    }
    if (!formData.living_situation) {
      validationErrors["living_situation"] = "Please select living situation.";
    }
    if (
      formData.living_situation === "Other" &&
      !formData.living_situation_details
    ) {
      validationErrors["living_situation_details"] =
        "Please Enter living situation details.";
    }
    if (!formData.home_has_elevator) {
      validationErrors["home_has_elevator"] =
        "Please specify if the house has an elevator.";
    }
    if (!formData.floor) {
      validationErrors["floor"] = "Please select the floor.";
    }
    if (formData.outdoor_spaces.length === 0) {
      validationErrors["outdoor_spaces"] =
        "Please select at least one Outdoor Space.";
    }
    if (formData.nearby_places.length === 0) {
      validationErrors["nearby_places"] =
        "Please select at least one Nearby Place.";
    }
    if (!formData.family_details) {
      validationErrors["family_details"] = "Family Details is required.";
    }
    if (!formData.have_children) {
      validationErrors["have_children"] =
        "Please select if you have children at home.";
    }
    if (formData.have_children === "Yes" && !formData.children_details) {
      validationErrors["children_details"] = "Children Details is required.";
    }
    if (!formData.have_your_own_dog) {
      validationErrors["have_your_own_dog"] =
        "Please select if you have your own dog.";
    }
    if (formData.have_your_own_dog === "Yes" && !formData.sitter_dog_info) {
      validationErrors["sitter_dog_info"] = "Sitter Dog Info is required.";
    }
    if (!formData.have_your_own_cat) {
      validationErrors["have_your_own_cat"] =
        "Please select if you have your own cat.";
    }
    if (!formData.has_driving_license) {
      validationErrors["has_driving_license"] =
        "Please select if you have a driving license.";
    }
    if (!formData.has_car) {
      validationErrors["has_car"] =
        "Please select if you have access to a car.";
    }
    if (!formData.dog_emergency_details) {
      validationErrors["dog_emergency_details"] =
        "Dog Emergency Details is required.";
    }
    if (!formData.daily_schedule) {
      validationErrors["daily_schedule"] = "Please select your daily schedule.";
    }
    if (!formData.daily_schedule_details) {
      validationErrors["daily_schedule_details"] =
        "Daily Schedule Details is required.";
    }
    if (isEditingAddress && !imageUrl2) {
      validationErrors["address_proof_image"] = "Please Upload Address Proof.";
    }

    // Check if there are any validation errors
    if (Object.keys(validationErrors).length > 0) {
      setValidationMessage(validationErrors);
    } else {
      // If there are no validation errors, proceed with form submission
      setValidationMessage("");
      const payload = {
        street_name: formData.street_name,
        city: formData.city,
        country: formatPostCountry(formCountry),
        postal_code: formData.postal_code,
        home_type: formData.home_type,
        home_size: formData.home_size,
        living_situation: formData.living_situation,
        living_situation_details: formData.living_situation_details,
        home_has_elevator: formData.home_has_elevator,
        floor: formData.floor,
        outdoor_spaces: formatPostMultiSelect(formData.outdoor_spaces),
        nearby_places: formatPostMultiSelect(formData.nearby_places),
        family_details: formData.family_details,
        have_children: formData.have_children === "Yes",
        children_details: formData.children_details,
        have_your_own_dog: formData.have_your_own_dog === "Yes",
        sitter_dog_info: formData.sitter_dog_info,
        have_your_own_cat: formData.have_your_own_cat === "Yes",
        has_driving_license: formData.has_driving_license === "Yes",
        has_car: formData.has_car === "Yes",
        dog_emergency_details: formData.dog_emergency_details,
        daily_schedule: formData.daily_schedule,
        daily_schedule_details: formData.daily_schedule_details,
        ...(imageUrl2 ? { address_proof_image: imageUrl2 } : {}),
      };
      await updateData(payload);
      setEditMode(false);
      setIsUploading(false);
      setIsEditingAddress(false);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: editMode ? "#f9eae3" : provided.backgroundColor,
    }),
    multiValueRemove: (base) => {
      return !editMode ? { ...base, display: "none" } : { ...base };
    },
  };

  const customStylesAddress = {
    control: (provided) => ({
      ...provided,
      backgroundColor: isEditingAddress ? "#f9eae3" : provided.backgroundColor,
    }),
    multiValueRemove: (base) => {
      return !isEditingAddress ? { ...base, display: "none" } : { ...base };
    },
  };

  return (
    <>
      <Row>
        <Card>
          <CardBody>
            <CardTitle>
              <span
                style={{ fontSize: "1.5rem", borderBottom: "1px solid #000" }}
              >
                Address Information
              </span>
              <div style={{ float: "right", marginRight: "10px" }}>
                {isEditingAddress ? (
                  <FaWindowClose onClick={handleAddressChangeCancel} />
                ) : (
                  <FaPen onClick={handleAddressChange} />
                )}
              </div>
            </CardTitle>

            <Form>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="street_name">Street Name & Number</Label>
                    <Input
                      name="street_name"
                      placeholder="Enter Street"
                      value={formData.street_name}
                      onChange={handleInputChange}
                      type="textarea"
                      className="form-control"
                      id="street_name"
                      disabled={!isEditingAddress}
                      style={{
                        backgroundColor: `${isEditingAddress ? "#f9eae3" : ""}`,
                      }}
                    />
                    {validationMessage.street_name && (
                      <div style={{ color: "red" }}>
                        {validationMessage.street_name}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="city">Town/Village/City</Label>
                    <Input
                      name="city"
                      placeholder="Enter Town/Village/City"
                      value={formData.city}
                      onChange={handleInputChange}
                      type="textarea"
                      className="form-control"
                      id="city"
                      disabled={!isEditingAddress}
                      style={{
                        backgroundColor: `${isEditingAddress ? "#f9eae3" : ""}`,
                      }}
                    />
                    {validationMessage.city && (
                      <div style={{ color: "red" }}>
                        {validationMessage.city}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="country">Country</Label>
                    <Select
                      className="select-font"
                      options={countryOptions}
                      value={formCountry}
                      onChange={handleCountryChange}
                      isDisabled={!isEditingAddress}
                      styles={customStylesAddress}
                    />
                    {validationMessage.country && (
                      <div style={{ color: "red" }}>
                        {validationMessage.country}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="postal_code">Post Code</Label>
                    <Input
                      name="postal_code"
                      placeholder="Enter Postal Code"
                      value={formData.postal_code}
                      onChange={handleInputChange}
                      type="text"
                      className="form-control"
                      id="postal_code"
                      disabled={!isEditingAddress}
                      style={{
                        backgroundColor: `${isEditingAddress ? "#f9eae3" : ""}`,
                      }}
                    />
                    {validationMessage.postal_code && (
                      <div style={{ color: "red" }}>
                        {validationMessage.postal_code}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="comfortable_with_cat_or_other">
                      Address Proof
                    </Label>
                    {isEditDocument ? (
                      <div>
                        <Input
                          type="file"
                          accept=".jpg,.jpeg,.png,.pdf,.webp,.doc"
                          onChange={(e) => handleImage2(e)}
                        />
                        {isUploading && (
                          <h4 className="mt-4">
                            Document Uploaded:{" "}
                            <img
                              src={successImg}
                              alt="successful.gif"
                              border="0"
                              style={{ width: "33px" }}
                            />
                          </h4>
                        )}
                        <p className="text-end text-size">Max Size: 5MB</p>
                        {!isEditingAddress && (
                          <button
                            className="btn doc-view-btn ms-2"
                            onClick={() => setIsEditDocument(false)}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    ) : (
                      imageUrl2 && (
                        <div className="text-center">
                          <a
                            className="address-proof btn doc-view-btn"
                            href={imageUrl2}
                            alt="View Image"
                            target="_blank"
                          >
                            View Document
                          </a>
                          {isEditingAddress && (
                            <>
                              <button onClick={() => setIsEditDocument(true)}>
                                Edit Document
                              </button>
                            </>
                          )}
                        </div>
                      )
                    )}
                    {validationMessage.address_proof_image && (
                      <div style={{ color: "red" }}>
                        {validationMessage.address_proof_image}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Row>
      <Form>
        <Row>
          {!isEditingAddress && (
            <Col md="12">
              <div style={{ float: "right", marginRight: "10px" }}>
                {editMode ? (
                  <FaWindowClose onClick={() => {
                    setEditMode(false);
                    setValidationMessage("");
                  }} />
                ) : (
                  <FaPen
                    onClick={() => {
                      setEditMode(true);
                      setValidationMessage("");
                    }}
                  />
                )}
              </div>
            </Col>
          )}
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="home_type">
                Do you live in a House or Apartment?
              </Label>
              <Input
                name="home_type"
                placeholder="Enter "
                value={formData.home_type}
                onChange={handleInputChange}
                type="select"
                className="form-control"
                id="home_type"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              >
                <option hidden>Select</option>
                <option value="House">House</option>
                <option value="Apartment">Apartment</option>
              </Input>
              {validationMessage.home_type && (
                <div style={{ color: "red" }}>
                  {validationMessage.home_type}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <Label htmlFor="home_size">
                Please tell us the approximate size of your home in m2
              </Label>
              <Input
                name="home_size"
                placeholder="Enter Home Size"
                value={formData.home_size}
                onChange={handleInputChange}
                type="text"
                className="form-control"
                id="home_size"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              />
              {validationMessage.home_size && (
                <div style={{ color: "red" }}>
                  {validationMessage.home_size}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="living_situation">
                Please choose the one that matches your living situation:
              </Label>
              <Input
                name="living_situation"
                placeholder="Enter Living Situation"
                value={formData.living_situation}
                onChange={handleInputChange}
                type="select"
                className="form-control"
                id="living_situation"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              >
                <option hidden>Select</option>
                <option value="You/Your family is the home owner">
                  You/Your family is the home owner
                </option>
                <option value="You are a tenant">You are a tenant</option>
                <option value="You are sharing with multiple tenants">
                  You are sharing with multiple tenants
                </option>
                <option value="Other">Other</option>
              </Input>
              {validationMessage.living_situation && (
                <div style={{ color: "red" }}>
                  {validationMessage.living_situation}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          {formData.living_situation === "Other" && (
            <Col md="12">
              <FormGroup>
                <Label htmlFor="living_situation_details">
                  Living Situation Details
                </Label>
                <Input
                  name="living_situation_details"
                  placeholder="Enter Living Situation Details"
                  value={formData.living_situation_details}
                  onChange={handleInputChange}
                  type="textarea"
                  className="form-control"
                  id="living_situation_details"
                  disabled={!editMode}
                  style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                />
                {validationMessage.living_situation_details && (
                  <div style={{ color: "red" }}>
                    {validationMessage.living_situation_details}
                  </div>
                )}
              </FormGroup>
            </Col>
          )}
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="floor">Which floor do you live on?</Label>
              <Input
                name="floor"
                placeholder="Enter "
                value={formData.floor}
                onChange={handleInputChange}
                type="select"
                className="form-control"
                id="floor"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              >
                <option hidden>Select</option>
                <option value="Ground floor">Ground floor</option>
                <option value="First floor">First floor</option>
                <option value="Second floor">Second floor</option>
                <option value="Third floor">Third floor</option>
                <option value="Higher than third floor">
                  Higher than third floor
                </option>
              </Input>
              {validationMessage.floor && (
                <div style={{ color: "red" }}>{validationMessage.floor}</div>
              )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="home_has_elevator">
                Does your building have an elevator?
              </Label>
              <Input
                name="home_has_elevator"
                placeholder="Enter Details"
                value={formData.home_has_elevator}
                onChange={handleInputChange}
                type="select"
                className="form-control"
                id="home_has_elevator"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="not_relevant">
                  Live on ground floor so not relevant
                </option>
              </Input>
              {validationMessage.home_has_elevator && (
                <div style={{ color: "red" }}>
                  {validationMessage.home_has_elevator}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="outdoor_spaces">
                Outdoor Spaces <strong>INSIDE</strong> Your Home
              </Label>
              <Select
                className="select-font"
                options={outdoor_spaces_options}
                isMulti
                onChange={handleOutdoorSpaces}
                value={formData.outdoor_spaces}
                name="outdoor_spaces"
                placeholder="Select"
                isDisabled={!editMode}
                styles={customStyles}
              />
              {validationMessage.outdoor_spaces && (
                <div style={{ color: "red" }}>
                  {validationMessage.outdoor_spaces}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <Label htmlFor="nearby_places">Green Spaces <strong>NEAR</strong> Your Home</Label>
              <Select
                className="select-font"
                options={nearby_places_options}
                isMulti
                onChange={handleNearbyPlace}
                value={formData.nearby_places}
                name="nearby_places"
                placeholder="Select"
                isDisabled={!editMode}
                styles={customStyles}
              />
              {validationMessage.nearby_places && (
                <div style={{ color: "red" }}>
                  {validationMessage.nearby_places}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="family_details">
                Who lives with you, would interact with dogs you care for and
                how do they feel about dogs?
              </Label>
              <Input
                name="family_details"
                placeholder="Enter Details"
                value={formData.family_details}
                onChange={handleInputChange}
                type="textarea"
                errorMessage="Family Details is required"
                className="form-control"
                id="family_details"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              />
              {validationMessage.family_details && (
                <div style={{ color: "red" }}>
                  {validationMessage.family_details}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="have_your_own_cat">
                Do you have a cat at home here in Luxembourg?
              </Label>
              <Input
                name="have_your_own_cat"
                placeholder="Enter have_your_own_cat"
                value={formData.have_your_own_cat}
                onChange={handleInputChange}
                type="select"
                errorMessage="Please select if you have your own cat."
                className="form-control"
                id="have_your_own_cat"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
              {validationMessage.have_your_own_cat && (
                  <div style={{ color: "red" }}>
                    {validationMessage.have_your_own_cat}
                  </div>
                )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="have_your_own_dog">
                Do you have your own dog here in Luxembourg?
              </Label>
              <Input
                name="have_your_own_dog"
                placeholder="Enter "
                value={formData.have_your_own_dog}
                onChange={handleInputChange}
                type="select"
                errorMessage="have_your_own_dog is required"
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="have_your_own_dog"
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
              {validationMessage.have_your_own_dog && (
                <div style={{ color: "red" }}>
                  {validationMessage.have_your_own_dog}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {formData.have_your_own_dog === "Yes" && (
            <Col md="12">
              <FormGroup>
                <Label htmlFor="sitter_dog_info">
                  Please tell us about your dog (Name, Gender, Breed, Age,
                  Neutered or not) and is your dog good with other dogs?
                </Label>
                <Input
                  name="sitter_dog_info"
                  placeholder="Enter Sitter Dog info"
                  value={formData.sitter_dog_info}
                  onChange={handleInputChange}
                  type="textarea"
                  errorMessage="sitter_dog_info is required"
                  className="form-control"
                  disabled={!editMode}
                  style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                  id="sitter_dog_info"
                />
                {validationMessage.sitter_dog_info && (
                  <div style={{ color: "red" }}>
                    {validationMessage.sitter_dog_info}
                  </div>
                )}
              </FormGroup>
            </Col>
          )}
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="have_children">
                Are There Children in Your Home?
              </Label>
              <Input
                name="have_children"
                placeholder="Enter Details"
                value={formData.have_children}
                onChange={handleInputChange}
                type="select"
                errorMessage="Please select if you have children at home"
                className="form-control"
                id="have_children"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
              {validationMessage.have_children && (
                <div style={{ color: "red" }}>
                  {validationMessage.have_children}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          {formData.have_children === "Yes" && (
            <Col md="12">
              <FormGroup>
                <Label htmlFor="children_details">
                  If YES, please tell us about the children (how old are they?
                  are they comfortable around dogs? Do they have experience with
                  dogs? Will they interact with the dogs only under
                  supervision?)
                </Label>
                <Input
                  name="children_details"
                  placeholder="Enter Details"
                  value={formData.children_details}
                  onChange={handleInputChange}
                  type="textarea"
                  errorMessage="Children Details is required"
                  className="form-control"
                  id="children_details"
                  disabled={!editMode}
                  style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                />
                {validationMessage.children_details && (
                  <div style={{ color: "red" }}>
                    {validationMessage.children_details}
                  </div>
                )}
              </FormGroup>
            </Col>
          )}
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="has_driving_license">
                Do you have a driving license?
              </Label>
              <Input
                name="has_driving_license"
                placeholder="Enter "
                value={formData.has_driving_license}
                onChange={handleInputChange}
                type="select"
                errorMessage="Please select if you have a driving license."
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="has_driving_license"
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
              {validationMessage.has_driving_license && (
                <div style={{ color: "red" }}>
                  {validationMessage.has_driving_license}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <Label htmlFor="has_car">
                Do you have access to your own or a family car?
              </Label>
              <Input
                name="has_car"
                placeholder="Enter Details"
                value={formData.has_car}
                onChange={handleInputChange}
                type="select"
                errorMessage="Please select if you have access to a car."
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="has_car"
              >
                <option hidden>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
              {validationMessage.has_car && (
                <div style={{ color: "red" }}>{validationMessage.has_car}</div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="dog_emergency_details">
                In the case of an emergency situation would you be able to take
                a dog to an emergency vet? Please explain how you would manage
                the situation.
              </Label>
              <Input
                name="dog_emergency_details"
                placeholder="Enter Details"
                value={formData.dog_emergency_details}
                onChange={handleInputChange}
                type="textarea"
                errorMessage="Dog Emergency Details is required."
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="dog_emergency_details"
              />
              {validationMessage.dog_emergency_details && (
                <div style={{ color: "red" }}>
                  {validationMessage.dog_emergency_details}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="daily_schedule">
                What is your work/daily schedule?
              </Label>
              <Input
                name="daily_schedule"
                placeholder="Enter "
                value={formData.daily_schedule}
                onChange={handleInputChange}
                type="select"
                errorMessage="Please select your daily schedule."
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="daily_schedule"
              >
                <option hidden>Select</option>
                <option value="My work is flexible">My work is flexible</option>
                <option value="I work from office">I work from office</option>
                <option value="I work from home">I work from home</option>
                <option value="I work only part time">
                  I work only part time
                </option>
                <option value="I work full time in office">
                  I work full time in office
                </option>
                <option value="I don't work at the moment">
                  I don't work at the moment
                </option>
                <option value="I am a student">I am a student</option>
                <option value="Other">Other</option>
              </Input>
              {validationMessage.daily_schedule && (
                <div style={{ color: "red" }}>
                  {validationMessage.daily_schedule}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <Label htmlFor="daily_schedule_details">
                Please share additional details about your daily schedule (what
                are your timings at work? are they flexible? can you work from
                home? etc.) We want to understand how you would manage your
                schedule when you are caring for a dog? Please explain as you
                wish. This is very important for us to understand.
              </Label>
              <Input
                name="daily_schedule_details"
                placeholder="Enter Details"
                value={formData.daily_schedule_details}
                onChange={handleInputChange}
                type="textarea"
                errorMessage="Daily Schedule Details is required."
                className="form-control"
                disabled={!editMode}
                style={{ backgroundColor: `${editMode ? "#f9eae3" : ""}` }}
                id="daily_schedule_details"
              />
              {validationMessage.daily_schedule_details && (
                <div style={{ color: "red" }}>
                  {validationMessage.daily_schedule_details}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          {editMode && (
            <Row>
              <Col md="6">
                <FormGroup>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                  >
                    Update
                  </button>
                </FormGroup>
              </Col>
            </Row>
          )}
        </Row>
      </Form>
      <Modal
        isOpen={isAddressModalOpen}
        toggle={() => setAddressModalOpen(!isAddressModalOpen)}
      >
        <ModalBody>
          <h4>
            Changing your address will reset all the home related information in
            your profile. Are you sure you want to continue?
          </h4>
          <Row>
            <Col className="text-center" xs="6">
              {" "}
              {/* Adjust the column size as needed */}
              <DynamicButton
                createBtnText="Yes"
                clickFunction={() => handleAddressChangeConfirm(true)}
              />
            </Col>
            <Col className="text-center" xs="6">
              {" "}
              {/* Adjust the column size as needed */}
              <DynamicButton
                createBtnText="No"
                clickFunction={() => handleAddressChangeConfirm(false)}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {isUploadAlertModal && (
        <div>
          <Modal
            size="lg"
            isOpen={isUploadAlertModal}
            centered
            backdrop="static"
          >
            <ModalBody>
              <h4 className="alert-msg text-center">
                This file can't be uploaded
              </h4>
              <h6 className="alert-msg text-center">
                Only Max size upto 5Mb can be uploaded
              </h6>
              <h6 className="alert-msg text-center">
                Click on choose file to select image again
              </h6>
              <div className="col-12 text-center">
                <DynamicButton
                  createBtnText="Close"
                  clickFunction={() => {
                    setIsUploadAlertModal(false);
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
};

export default HomeSchedule;
