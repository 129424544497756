export const baseDomain = "https://api.dogstays.lu/"

export const sitterUserForm = 'account/sitter/signup' // POST	

export const loginAPI = 'account/sitter/login' // POST	

export const sitterKfcForm = 'account/sitter/profile/form' // PUT

export const calenderEvent = 'account/sitter/event/list' //  GET

export const createCalenderEvent = 'account/sitter/event/create/test' //  POST

export const deleteCalenderEvent = 'account/sitter/event/cancel' //  PUT

export const editCalenderEvent = 'account/sitter/event/edit' //  PUT

export const getCalenderEventById = 'account/sitter/event/detail' //  GET

export const getsitterkfcForm = 'account/sitter/self/profile' //GET

export const getBookingData = 'account/sitter/booking/details' //GET 

export const updatesitterkfcForm = 'account/sitter/edit/profile' //PUT


// eslint-disable-next-line
export default `${baseDomain}`
