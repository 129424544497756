import { useEffect, } from 'react'
import { useNavigate } from 'react-router-dom'

const DecisionComponent = () => {
  const navigate = useNavigate()
  const isAuthorised = localStorage.getItem('token')
  const isKycDocsUploaded = localStorage.getItem('isKycDocsUploaded')
  useEffect(() => {
    isAuthorised ? isKycDocsUploaded ? navigate('/sitter/home') : navigate('/bookingpreferences') :  navigate('/applicationstart')
  }, [])

  return null
}

export default DecisionComponent