import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Button
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import { useState } from "react";
import "./style.css";
import { DynamicButton } from "../../../../Components/DynamicButton";
import axios from "axios";
import { createSitterKycReq } from "./api";
import { toast } from "react-toastify";
import { clearLocal, isAuthorized } from "../../../../Utils/localStorage";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import successImg from '../../../../Assests/Images/undraw_done_re_oak4.svg'

const SitterKycForm = () => {
  const [imageUrl1, setImageUrl1] = useState(null);
  const [imageUrl2, setImageUrl2] = useState("");
  const [offeredServices, setOfferedServices] = useState([]);
  const [sizeOfDog, setSizeOfDog] = useState([]);
  const [ageOfDog, setAgeOfDog] = useState([]);
  const [outdoorSpaces, setOutdoorSpaces] = useState([]);
  const [nearbyPlaces, setNearbyPlaces] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [isUploadAlertModal, setIsUploadAlertModal] = useState(false);
  const [isAgreementModal, setIsAgreementModal] = useState(false);
  const [isPrivacyAlertModal, setIsPrivacyAlertModal] = useState(false);
  const [validateCheck, setValidateCheck] = useState(false);
  const [validateCheckAgreement, setValidateCheckAgreement] = useState(false);
  const [isAgreementAccepted, setIsAgreementAccepted] = useState(false)
  const [isAllCheckAlertModal, setIsAllCheckAlertModal] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthorized() && window.localStorage.getItem('userStatus') === 'approved') navigate("/sitter/home");
  }, []);

  const handleCheck = (e) => {
    e.target.checked ? setValidateCheck(true) : setValidateCheck(false);
  };

  const handleCheckAgreement = (e) => {
    e.target.checked ? setIsAgreementModal(true) : setIsAgreementModal(false);
  };

  const handelAgreementInput = () => {
    setIsAgreementAccepted(true)
    setValidateCheckAgreement(true)
    setIsAgreementModal(false)
  } 
  // const [userData, setUserData] = useState({
  //   firstName: '',
  //   lastName: '',
  //   dateOfBirth: '',
  //   address: ''
  // })

  // const{ firstName, lastName, dateOfBirth, address} = userData

  // useEffect(() => {
  //   setUserData({
  //     ...userData,
  //     firstName : window.localStorage.getItem('userDataFName')
  //   })
  //   console.log(firstName)
  // }, [])

  // const abc = new URLSearchParams(window.location.search).get('b')

  // console.log('asasa',window.location.search, abc)

  const [activeTab, setActiveTab] = useState(1);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setActiveTab(tab);
      }
    }
  };

  const UploadImage = async (image) => {
    return await axios
      .post("https://api.dogstays.lu/basic/upload/image", image)
      .then((res) => {
        setImageUrl1(res.data.data.url);
      });
  };

  const handleImage = (e) => {
    const uploadFile = e.target.files[0];
    const fileSize = e.target.files[0].size;
    const imageFormData = new FormData();
    imageFormData.append("image", uploadFile);
    fileSize < "5000000"
      ? UploadImage(imageFormData)
      : setIsUploadAlertModal(true);
  };

  const UploadImage2 = async (image) => {
    return await axios
      .post("https://api.dogstays.lu/basic/upload/image", image)
      .then((res) => {
        setImageUrl2(res.data.data.url);
      });
  };

  const handleImage2 = (e) => {
    const uploadFile = e.target.files[0];
    const fileSize = e.target.files[0].size;
    const imageFormData = new FormData();
    imageFormData.append("image", uploadFile);
    fileSize < "5000000"
      ? UploadImage2(imageFormData)
      : setIsUploadAlertModal(true);
  };

  const resetImg = () => {
    setImageUrl1(null);
    console.log("jasmeet");
  };

  const resetImg2 = () => {
    setImageUrl2(null);
    console.log("jasmeet");
  };

  const offered_services_options = [
    {
      value: "overnight_sitter_home",
      label: "Overnight Boarding Sitter's Home",
    },
    {
      value: "overnight_parent_home",
      label: "Overnight Boarding Dog Parent's Home",
    },
    { value: "daycare_sitter_home", label: "Daycare Sitter's Home" },
    { value: "daycare_parent_home", label: "Daycare Dog Parent's Home" },
  ];

  const size_of_dog_options = [
    { value: "small", label: "Small (less than 14 kilos)" },
    { value: "medium", label: "Medium (between 14 and 25 kilos)" },
    { value: "large", label: "Large (more than 25 kilos)" },
    { value: "all_size", label: "All sizes OK" },
  ];

  const outdoor_spaces_options = [
    { value: "Terrace", label: "Terrace" },
    { value: "Garden", label: "Garden" },
    { value: "Balcony", label: "Balcony" },
    { value: "Yard", label: "Yard" },
    { value: "No green space", label: "None" }
  ];

  const nearby_places_options = [
    { value: "Park", label: "Park" },
    { value: "Forest", label: "Forest" },
    { value: "Hiking trail", label: "Hiking trail" },
    { value: "Dog Park", label: "Dog Park" },
    { value: "Walking Path", label: "Walking Path" },
    { value: "No green space", label: "None" }
  ];

  const age_of_dog_options = [
    { value: "young_puppy", label: "Very Young Puppy (less than 1 year old)" },
    { value: "puppy", label: "Puppy (less than 2 years old)" },
    { value: "adult_dog", label: "Adult dog (over 2 years old)" },
    { value: "senior_dog", label: "Senior dog (above 7 years old)" },
    { value: "all_age", label: "All ages welcome" },
  ];

  const handleService = (val) => {
    setOfferedServices(val);
    console.log(formatMultiSelectData(offeredServices));
  };

  const handleSize = (val) => {
    setSizeOfDog(val);
  };

  const handleNearbyPlace = (val) => {
    setNearbyPlaces(val);
  };

  const handleAge = (val) => {
    setAgeOfDog(val);
  };

  const handleOutdoorSpaces = (val) => {
    setOutdoorSpaces(val);
  };

  const formatMultiSelectData = (data) => {
    const formatedData = data.map(({ value }) => value);
    return formatedData;
  };

  const [formData, setFormData] = useState({
    daily_schedule: "",
    daily_schedule_details: "",
    home_type: "",
    home_size: "",
    living_situation: "",
    floor: "",
    home_has_elevator: "",
    outdoor_spaces: "",
    family_details: "",
    have_children: "",
    children_details: "",
    has_driving_license: "",
    has_car: "",
    dog_emergency_details: "",
    offered_services: [],
    size_of_dog: [],
    age_of_dog: [],
    comfortable_with_non_toilet_trained: "",
    comfortable_with_two_dogs: "",
    comfortable_with_separation_anxiety: "",
    comfortable_with_cat_or_other: "",
    profileImage: "",
    passport_image: null,
    address_proof_image: null,
  });

  const {
    daily_schedule,
    daily_schedule_details,
    home_type,
    home_size,
    living_situation,
    floor,
    home_has_elevator,
    outdoor_spaces,
    family_details,
    have_children,
    children_details,
    has_driving_license,
    has_car,
    dog_emergency_details,
    offered_services,
    size_of_dog,
    age_of_dog,
    comfortable_with_non_toilet_trained,
    comfortable_with_two_dogs,
    comfortable_with_separation_anxiety,
    comfortable_with_cat_or_other,
    profileImage,
  } = formData;

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
      const { data } = await createSitterKycReq({
        daily_schedule,
        daily_schedule_details,
        home_type,
        home_size,
        living_situation,
        floor,
        home_has_elevator,
        outdoor_spaces: formatMultiSelectData(outdoorSpaces),
        nearby_places: formatMultiSelectData(nearbyPlaces),
        family_details,
        have_children,
        children_details,
        has_driving_license,
        has_car,
        dog_emergency_details,
        offered_services: formatMultiSelectData(offeredServices),
        size_of_dog: formatMultiSelectData(sizeOfDog),
        age_of_dog: formatMultiSelectData(ageOfDog),
        comfortable_with_non_toilet_trained,
        comfortable_with_two_dogs,
        comfortable_with_separation_anxiety,
        comfortable_with_cat_or_other,
        profileImage,
        passport_image: imageUrl1,
        address_proof_image: imageUrl2,
      });

      if (data.response_code === 80) {
        navigate("/bookdetailsubmit")
        clearLocal();
      } else {
        toast.error(data.developer_message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
  };
  return (
    <>
      <div className={viewForm ? "d-none" : "d-block"}>
        <Container fluid className="contain">
          <Row className="parent-wrap">
            <div className="start-application-wrapper">
              {/* <h1>Your Schedule And Booking Preferences</h1> */}
              <h1>Hello! We are delighted that you are joining our community! 😊</h1>
              <p>
                We want to make sure we only send you bookings that are a good match for you.
                So, now we will ask you some questions about your home, your booking preferences
                and which services you wish to offer. 
              </p>
              <p>Please remember: as part of the process you will also need to upload scanned copies of your ID and a valid address proof document.
                 We request you to have these ready before you start.  </p>
              <Button
                className='dynamic-btn SitterForm2Start'
                style={{ width: "30%", fontSize: "15px" }}
                onClick={() => setViewForm(true)}
              >Start</Button>
              <ul className="info-list mt-4">
                <li>
                We accept the following address proofs - certificate of residence (Luxembourg),
                 a bank statement, phone bill or electricity bill from the last 3 months.
                </li>
                <li>
                Please note: The files you upload must be a maximum of 2MB each in size and can be any of the following file
                 types - Jpeg, webp, png, pdf, doc.
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>

      {viewForm && (
        <div className="form-main">
          <Container fluid={false} className="">
            <Row style={{ padding: "20px 0px" }}>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h2 className="Form-title mb-4 text-center">
                      Sitter Booking Details Form
                    </h2>
                    <p className="info-msg">In case you face any problems with this form please email us at dogstays@gmail.com or contact@dogstays.lu and we will help.</p>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === 1,
                            })}
                            // onClick={() => {
                            //   toggleTab(1);
                            // }}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">Your Home</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === 2,
                            })}
                            // onClick={() => {
                            //   toggleTab(2);
                            // }}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">
                              Booking Preferences
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === 3,
                            })}
                            // onClick={() => {
                            //   toggleTab(2);
                            // }}
                          >
                            <span className="step-number">03</span>
                            <span className="step-title">Documents</span>
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === 4,
                            })}
                            // onClick={() => {
                            //   toggleTab(3);
                            // }}
                          >
                            <span className="step-number">04</span>
                            <span className="step-title">Submitted</span>
                          </NavLink>
                        </NavItem> */}
                      </ul>

                      <TabContent
                        activeTab={activeTab}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <div className="row">
                              <div className="col-md-6 division">
                                <FormGroup>
                                  <Label htmlFor="daily_schedule">
                                    What is your work/daily schedule?
                                  </Label>
                                  <Input
                                    name="daily_schedule"
                                    placeholder="Enter "
                                    value={daily_schedule}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="daily_schedule is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="daily_schedule"
                                  >
                                    <option hidden>Select</option>
                                    <option value="My work is flexible">
                                      My work is flexible
                                    </option>
                                    <option value="I work from office">
                                      I work from office
                                    </option>
                                    <option value="I work from home">
                                      I work from home
                                    </option>
                                    <option value="I work only part time">
                                      I work only part time
                                    </option>
                                    <option value="I work full time in office">
                                      I work full time in office
                                    </option>
                                    <option value="I don't work at the moment">
                                      I don't work at the moment
                                    </option>
                                    <option value="I am a student">
                                      I am a student
                                    </option>
                                    <option value="Other">Other</option>
                                  </Input>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="daily_schedule_details">
                                    Please share additional details about your
                                    daily schedule (what are your timings at
                                    work? are they flexible? can you work from
                                    home? etc.) We want to understand how you
                                    would manage your schedule when you are
                                    caring for a dog? Please explain as you
                                    wish. This is very important for us to
                                    understand.
                                  </Label>
                                  <Input
                                    name="daily_schedule_details"
                                    placeholder="Enter Details"
                                    value={daily_schedule_details}
                                    onChange={handelInput}
                                    type="textarea"
                                    errorMessage="daily_schedule_details is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="daily_schedule_details"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="home_type">
                                    Do you live in a House or Apartment?
                                  </Label>
                                  <Input
                                    name="home_type"
                                    placeholder="Enter "
                                    value={home_type}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="Home Type is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="home_type"
                                  >
                                    <option hidden>Select</option>
                                    <option value="House">House</option>
                                    <option value="Apartment">Apartment</option>
                                  </Input>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="home_size">
                                    Please tell us the approximate size of your
                                    home in m2
                                  </Label>
                                  <Input
                                    name="home_size"
                                    placeholder="Enter Home Size"
                                    value={home_size}
                                    onChange={handelInput}
                                    type="text"
                                    errorMessage="home_size is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="home_size"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="living_situation">
                                    Please choose the one that matches your
                                    living situation:
                                  </Label>
                                  <Input
                                    name="living_situation"
                                    placeholder="Enter Living Situation"
                                    value={living_situation}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="living_situation is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="living_situation"
                                  >
                                    <option hidden>Select</option>
                                    <option value="You/Your family is the home owner">
                                      You/Your family is the home owner
                                    </option>
                                    <option value="You are a tenant">
                                      You are a tenant
                                    </option>
                                    <option value="You are sharing with multiple tenants">
                                      You are sharing with multiple tenants
                                    </option>
                                    <option value="Other">Other</option>
                                  </Input>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="floor">
                                    Which floor do you live on?
                                  </Label>
                                  <Input
                                    name="floor"
                                    placeholder="Enter "
                                    value={floor}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="floor is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="floor"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Ground floor">
                                      Ground floor
                                    </option>
                                    <option value="First floor">
                                      First floor
                                    </option>
                                    <option value="Second floor">
                                      Second floor
                                    </option>
                                    <option value="Third floor">
                                      Third floor
                                    </option>
                                    <option value="Higher than third floor">
                                      Higher than third floor
                                    </option>
                                  </Input>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="home_has_elevator">
                                    Does your building have an elevator?
                                  </Label>
                                  <Input
                                    name="home_has_elevator"
                                    placeholder="Enter Details"
                                    value={home_has_elevator}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="home_has_elevator is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="home_has_elevator"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="not_relevant">
                                      Live on ground floor so not relevant
                                    </option>
                                  </Input>
                                </FormGroup>
                              </div>
                              <div className="col-md-6">
                                <FormGroup>
                                  <Label htmlFor="outdoor_spaces">
                                    What outdoor spaces do you have in your
                                    home? (choose all that apply)
                                  </Label>
                                  <Select
                                    className="select-font"
                                    options={outdoor_spaces_options}
                                    isMulti
                                    onChange={handleOutdoorSpaces}
                                    value={outdoorSpaces}
                                    name="outdoor_spaces"
                                    placeholder="Select"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="nearby_places">
                                    Which of these do you have near your home?
                                  </Label>
                                  <Select
                                    className="select-font"
                                    options={nearby_places_options}
                                    isMulti
                                    onChange={handleNearbyPlace}
                                    value={nearbyPlaces}
                                    name="nearby_places"
                                    placeholder="Select"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="family_details">
                                    Please tell who lives in your home with you.
                                    Who else would interact with the dogs you
                                    look after and how do they feel about dogs?
                                  </Label>
                                  <Input
                                    name="family_details"
                                    placeholder="Enter Details"
                                    value={family_details}
                                    onChange={handelInput}
                                    type="textarea"
                                    errorMessage="family_details is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="family_details"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="have_children">
                                    Do you have children at home and would they
                                    be interacting with the dog as well?
                                  </Label>
                                  <Input
                                    name="have_children"
                                    placeholder="Enter Details"
                                    value={have_children}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="have_children is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="have_children"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Input>
                                </FormGroup>
                                {have_children === "Yes" && (
                                  <FormGroup>
                                    <Label htmlFor="children_details">
                                      If YES, please tell us about the children
                                      (how old are they? are they comfortable
                                      around dogs? Do they have experience with
                                      dogs? Will they interact with the dogs
                                      only under supervision?)
                                    </Label>
                                    <Input
                                      name="children_details"
                                      placeholder="Enter Details"
                                      value={children_details}
                                      onChange={handelInput}
                                      type="textarea"
                                      errorMessage="children_details is required"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="children_details"
                                    />
                                  </FormGroup>
                                )}

                                <FormGroup>
                                  <Label htmlFor="has_driving_license">
                                    Do you have a driving license?
                                  </Label>
                                  <Input
                                    name="has_driving_license"
                                    placeholder="Enter "
                                    value={has_driving_license}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="has_driving_license is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="has_driving_license"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Input>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="has_car">
                                    Do you have access to your own or a family
                                    car?
                                  </Label>
                                  <Input
                                    name="has_car"
                                    placeholder="Enter Details"
                                    value={has_car}
                                    onChange={handelInput}
                                    type="select"
                                    errorMessage="has_car is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="has_car"
                                  >
                                    <option hidden>Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Input>
                                </FormGroup>

                                <FormGroup>
                                  <Label htmlFor="dog_emergency_details">
                                    In the case of an emergency situation would
                                    you be able to take a dog to an emergency
                                    vet? Please explain how you would manage the
                                    situation.
                                  </Label>
                                  <Input
                                    name="dog_emergency_details"
                                    placeholder="Enter Details"
                                    value={dog_emergency_details}
                                    onChange={handelInput}
                                    type="textarea"
                                    errorMessage="dog_emergency_details is required"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="dog_emergency_details"
                                  />
                                </FormGroup>
                              </div>
                            </div>
                          </Form>
                        </TabPane>

                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <FormGroup>
                                <Label htmlFor="offered_services">
                                  What services would you like to offer with
                                  DogStays?
                                </Label>
                                <Select
                                  className="select-font"
                                  options={offered_services_options}
                                  isMulti
                                  onChange={handleService}
                                  name="offered_services"
                                  placeholder="Select"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="size_of_dog">
                                  What all sizes of dogs are you comfortable
                                  caring for? (don't worry, you will always meet
                                  the dog before accepting a booking!)
                                </Label>
                                <Select
                                  className="select-font"
                                  options={size_of_dog_options}
                                  isMulti
                                  onChange={handleSize}
                                  name="size_of_dog"
                                  placeholder="Select"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="age_of_dog">
                                  Your are comfortable caring for dogs of what
                                  ages?
                                </Label>
                                <Select
                                  className="select-font"
                                  options={age_of_dog_options}
                                  isMulti
                                  onChange={handleAge}
                                  name="age_of_dog"
                                  placeholder="Select"
                                  maxMenuHeight={120}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="comfortable_with_non_toilet_trained">
                                  Are you comfortable caring for young dogs who
                                  are still being toilet trained?
                                </Label>
                                <Input
                                  name="comfortable_with_non_toilet_trained"
                                  placeholder="Enter Detail"
                                  value={comfortable_with_non_toilet_trained}
                                  onChange={handelInput}
                                  type="select"
                                  errorMessage="comfortable_with_non_toilet_trained is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="comfortable_with_non_toilet_trained"
                                >
                                  <option hidden>Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="Maybe">Maybe</option>
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="comfortable_with_two_dogs">
                                  Are you comfortable caring for 2 dogs from the
                                  same house?
                                </Label>
                                <Input
                                  name="comfortable_with_two_dogs"
                                  placeholder="Enter Detail"
                                  value={comfortable_with_two_dogs}
                                  onChange={handelInput}
                                  type="select"
                                  errorMessage="comfortable_with_two_dogs is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="comfortable_with_two_dogs"
                                >
                                  <option hidden>Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="Maybe">Maybe</option>
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="comfortable_with_separation_anxiety">
                                  Would you be open to caring for dogs with
                                  separation anxiety or other problems? (don't
                                  worry, you will always meet the dog before
                                  deciding to accept a particular booking)
                                </Label>
                                <Input
                                  name="comfortable_with_separation_anxiety"
                                  placeholder="Enter Detail"
                                  value={comfortable_with_separation_anxiety}
                                  onChange={handelInput}
                                  type="select"
                                  errorMessage="comfortable_with_separation_anxiety is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="comfortable_with_separation_anxiety"
                                >
                                  <option hidden>Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="Maybe">Maybe</option>
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="comfortable_with_cat_or_other">
                                  Are you comfortable caring for cats or other
                                  small animals?
                                </Label>
                                <Input
                                  name="comfortable_with_cat_or_other"
                                  placeholder="Enter Detail"
                                  value={comfortable_with_cat_or_other}
                                  onChange={handelInput}
                                  type="select"
                                  errorMessage="comfortable_with_cat_or_other is required"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="comfortable_with_cat_or_other"
                                >
                                  <option hidden>Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="Maybe">Maybe</option>
                                </Input>
                              </FormGroup>
                            </Form>
                          </div>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div className="row">
                            <div className="col-md-6">
                              <Form>
                                <FormGroup>
                                  <Label htmlFor="comfortable_with_cat_or_other">
                                    Identification Doucment (EU ID/Passport)
                                  </Label>
                                  <Input
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.pdf,.webp,.doc"
                                    onChange={(e) => handleImage(e)}
                                  />
                                  <p className="text-end text-size">
                                    Max Size: 5MB
                                  </p>
                                </FormGroup>

                                {imageUrl1 && (
                                  <div className="text-center">
                                    <h4 className="mt-4">
                                      {" "}
                                      Document Uploaded :{" "}
                                      <img
                                        src={successImg}
                                        alt="successful.gif"
                                        border="0"
                                        style={{ width: "33px" }}
                                      />
                                    </h4>
                                    <a
                                      className="btn doc-view-btn"
                                      href={imageUrl1}
                                      alt="View Image"
                                      target="_blank"
                                    >
                                      View Document
                                    </a>
                                    {/* <button className="btn doc-view-btn ms-2" onClick={resetImg}>Reset</button> */}
                                    <p className="text-center text-size mt-2">
                                      To upload new file click on choose file
                                    </p>
                                  </div>
                                )}
                              </Form>
                            </div>
                            <div className="col-md-6">
                              <Form>
                                <FormGroup>
                                  <Label htmlFor="comfortable_with_cat_or_other">
                                    Address Proof
                                  </Label>
                                  <Input
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.pdf,.webp,.doc"
                                    onChange={(e) => handleImage2(e)}
                                  />
                                  <p className="text-end text-size">
                                    Max Size: 5MB
                                  </p>
                                </FormGroup>
                                {imageUrl2 && (
                                  <div className="text-center">
                                    <h4 className="mt-4">
                                      {" "}
                                      Document Uploaded :{" "}
                                      <img
                                        src={successImg}
                                        alt="successful.gif"
                                        border="0"
                                        style={{ width: "33px" }}
                                      />
                                    </h4>
                                    <a
                                      className="btn doc-view-btn"
                                      href={imageUrl2}
                                      alt="View Image"
                                      target="_blank"
                                    >
                                      View Document
                                    </a>
                                    {/* <button className="btn doc-view-btn ms-2" onClick={resetImg2}>Reset</button> */}
                                    <p className="text-center text-size mt-2">
                                      To upload new file click on choose file
                                    </p>
                                  </div>
                                )}
                              </Form>
                            </div>
                            <FormGroup check className="my-3 text-center">
                              <Label check>
                                <Input
                                  type="checkbox"
                                  onChange={handleCheck}
                                  value={validateCheck}
                                />
                                I accept the DogStays
                                <a
                                  className="terms"
                                  href="/terms_privacy"
                                  target="_blank"
                                >
                                  {" "}
                                 Privacy Policy
                                </a>
                              </Label>
                            </FormGroup>
                            <FormGroup check className="my-3 text-center">
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={validateCheckAgreement}
                                  onChange={handleCheckAgreement}
                                />
                                Please click here and accept the DogStays Sitter Agreement.
                              </Label>
                            </FormGroup>
                          </div>
                        </TabPane>

                        {/* <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                  <a href="https://gifyu.com/image/SKwYo">
                                    <img
                                      src="https://s8.gifyu.com/images/successful.gif"
                                      alt="successful.gif"
                                      border="0"
                                      style={{ width: "130px" }}
                                    />
                                  </a>
                                </div>
                                <div>
                                  <h5>Application Submitted Thank You!</h5>
                                  <p className="text-muted">
                                    We will review the information you have
                                    shared with us and send you an email.
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane> */}
                      </TabContent>

                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          className={
                            activeTab === 4
                              ? "previous disabled d-none"
                              : "previous"
                          }
                        >
                          <DynamicButton
                            clickFunction={() => {
                              toggleTab(activeTab - 1);
                            }}
                            createBtnText="Previous"
                          />
                        </li>
                        <li
                          className={activeTab === 4 ? "next d-none" : "next"}
                        >
                          <DynamicButton
                            className={activeTab === 3 ? "disabled" : "none"}
                            clickFunction={() => {
                              if(activeTab === 1){
                                if(
                                  daily_schedule === "" ||
                                  daily_schedule_details === "" ||
                                  home_type === "" ||
                                  home_size === "" ||
                                  living_situation === "" ||
                                  floor === "" ||
                                  home_has_elevator === "" ||
                                  outdoorSpaces.length === 0 ||
                                  nearbyPlaces.length === 0 ||
                                  family_details === "" ||
                                  have_children === "" ||
                                  has_driving_license === "" ||
                                  has_car === "" ||
                                  dog_emergency_details === ""
                                ){
                                  toast.warning("Please fill all the fields", {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                  });
                                }else{
                                  toggleTab(activeTab + 1)
                                }
                              }

                              if(activeTab === 2){
                                if(
                                  offeredServices.length === 0 ||
                                  sizeOfDog.length === 0 ||
                                  ageOfDog.length === 0 ||
                                  comfortable_with_non_toilet_trained === "" ||
                                  comfortable_with_two_dogs === "" ||
                                  comfortable_with_separation_anxiety === "" ||
                                  comfortable_with_cat_or_other === ""
                                ){
                                  toast.warning("Please fill all the fields", {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                  });
                                }else{
                                  toggleTab(activeTab + 1)
                                }
                              }

                              if(activeTab === 3){
                                if(imageUrl1 === null || imageUrl2 === ''){
                                  toast.warning("Please Upload the Documents", {
                                          position: "top-right",
                                          autoClose: 3000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: false,
                                          progress: undefined,
                                        });
                                } else if(isAgreementAccepted === false || validateCheck === false){
                                  setIsAllCheckAlertModal(true)
                                  console.log(imageUrl2)
                                  }else{
                                    handleSubmit()
                                  }
                              }else{
                                console.log('sgwww')
                              }
                            }}
                            createBtnText={activeTab === 3 ? "Submit" : "Next"}
                          />
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {isUploadAlertModal && (
        <div>
          <Modal
            size="lg"
            isOpen={isUploadAlertModal}
            centered
            backdrop="static"
          >
            <ModalBody>
              <h4 className="alert-msg text-center">
                This file can't be uploaded
              </h4>
              <h6 className="alert-msg text-center">
                Only Max size upto 5Mb can be uploaded
              </h6>
              <h6 className="alert-msg text-center">
                Click on choose file to select image again
              </h6>
              <div className="col-12 text-center">
                <DynamicButton
                  createBtnText="Close"
                  clickFunction={() => {
                    setIsUploadAlertModal(false);
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}

      {isPrivacyAlertModal && (
        <div>
          <Modal
            size="lg"
            isOpen={isPrivacyAlertModal}
            centered
            backdrop="static"
          >
            <ModalBody>
              <h6 className="alert-msg text-center">
                To Continue, Please Accept the DogStays and Privacy 
                Policy
              </h6>
              <div className="col-12 text-center">
                <DynamicButton
                  createBtnText="Close"
                  clickFunction={() => {
                    setIsPrivacyAlertModal(false);
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}

      {isAgreementModal && (
        <div>
          <Modal size="lg" isOpen={isAgreementModal} centered backdrop="static">
            <ModalBody>
              <ModalHeader>
                <h3 className="alert-msg">Agreement With DogStays</h3>
              </ModalHeader>
              <p className="alert-msg mt-3">
                THIS IS A BUSINESS DEVELOPMENT CONTRACT DATED (the ‘Effective
                Date’): {new Date().toLocaleDateString()}
                <br />
                BETWEEN THE UNDERSIGNED:
              </p>
              <p className="alert-msg mb-2 mt-3">
                <b>DOGSTAYS SARL-S,</b> having its registered office at{" "}
                <b>5 RUE JEAN PIERRE LANTER, L - 5943, ITZIG, LUXEMBOURG</b> and
                registered in the Trade and Companies Register of LUXEMBOURG
                under the number:
                <b>B230716</b>.
              </p>
              <p className="alert-msg mb-2">
                Here in after referred to as 'DogStays'
              </p>
              <p className="alert-msg mb-2 font-weight-bold">AND</p>
              <p className="alert-msg">
                Madam / Sir [Name, First name]:
                <b>
                  {" "}
                  {window.localStorage.getItem("userDataLName")}, {" "}
                  {window.localStorage.getItem("userDataFName")}
                </b>{" "}
                residing at{" "}
                <b>{window.localStorage.getItem("userDataAddress")}</b>
              </p>
              <p className="alert-msg mb-2">
                Hereinafter referred to as 'You', or the 'Dog Sitter', each a
                'party' and together 'the parties'.{" "}
              </p>
              <div>
                <p className="alert-msg mb-2">WHEREAS: </p>
                <ul className="alert-msg">
                  <li>
                    A. DogStays is in the business of providing services to pet
                    owners (hereafter referred to as 'Client(s)') and these
                    Clients make pet care related bookings (hereafter referred
                    to as the 'Booking') with DogStays.{" "}
                  </li>
                  <li>
                    B. As part of providing this service, DogStays will from time
                    to time, and in its sole and absolute discretion, connect
                    You to Clients for pet care purposes.
                  </li>
                  <li>
                    C. Both parties wish to enter into this agreement, whereby
                    DogStays agrees to list You on its list of approved service
                    providers that DogStays connects with its Clients and You
                    agree to be an approved service provider so named on the
                    list.{" "}
                  </li>
                  <li>
                    D. You agree to the terms and conditions outlined in this
                    agreement.
                  </li>
                </ul>
              </div>

              <p className="alert-msg">
                <b>NOW, THEREFORE,</b> in consideration of the foregoing, and of
                the mutual covenants, agreements, and promises set forth herein,
                the parties agree as follows:
              </p>

              <ul className="alert-msg">
                <li>
                  1. Effective Date
                  <ul className="alert-msg">
                    <li>
                      <li>
                        1.1. The agreement commences as of the Effective Date of this
                        agreement.{" "}
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="alert-msg">
                <li>
                  2. Appointment
                  <ul className="alert-msg">
                    <li>
                      <li>
                        2.1. DogStays hereby appoints You to be part of its list of
                        approved dog sitters and You accept this appointment.
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="alert-msg">
                <li>
                  3. Obligations, Representations and Warranties of the Dog Sitter
                  <ul className="alert-msg">
                    <li>
                      <li>
                        3.1. You confirm that You are a resident of Luxembourg and
                        are fit to look after the Client's pet(s).
                      </li>
                      <li>
                        3.2. You agree to be completely honest and transparent when
                        sharing details, with both DogStays and the Client,
                        including but not limited to details about your
                        residential address, your daily schedule, your previous
                        experience with pets, the family members in your home
                        and other details relevant to the Booking. You confirm
                        that the address You will share with DogStays and the
                        Client is the address where You will care for the pet(s)
                        during any Bookings.
                      </li>
                      <li>
                        3.3. You acknowledge and confirm that the Booking will be
                        confirmed once You confirm availability of your
                        calendar. You also acknowledge and confirm that DogStays
                        will keep 20% of the total charges of the Booking and
                        that DogStays will take this amount as a Booking amount,
                        before the Booking starts.
                      </li>
                      <li>
                      3.4. In the case that You will be caring for another pet
                        during the same dates and at the same address as
                        detailed in the Booking, or in the case that there is an
                        overlap between one Booking and another, it is your
                        responsibility to inform the Client and DogStays and
                        procure the Client’s acceptance before the Booking
                        begins.
                      </li>
                      <li>
                      3.5. In the case that You will be caring for the Client’s
                        pet(s) at the Client’s own home during the Booking, You
                        agree to do this in accordance with the Client’s
                        instructions and requests with respect to the safety of
                        his/her home and belongings.
                      </li>
                      <li>
                      3.6. In the case that You will be caring for the Client’s
                        pet(s) at the Client’s own home during the Booking, You
                        agree not to give any third-party access to the Client’s
                        home unless You have taken prior permission from the
                        Client to do this.
                      </li>
                      <li>
                      3.7. In the case that You will be caring for the Client’s
                        pet(s) at the Client’s own home during the Booking, You
                        agree to treat with the utmost care, objects like the
                        key or access code to the Client’s home.
                      </li>
                      <li>
                      3.8. You agree to care for the Client’s pet(s) in the manner
                        specified by the Client in the DogStays Information Form
                        shared with You by DogStays and to not deviate from
                        these instructions. In case You wish to take any action
                        not detailed or agreed therein, You commit to checking
                        with the Client before proceeding.
                      </li>
                      <li>
                      3.9. You agree not to engage any Client You are connected
                        with through DogStays, either directly or indirectly,
                        for any kind of business arrangement or pet care
                        arrangement, at any point in the future and agree to
                        contact the Client only through DogStays. You also agree
                        to inform DogStays immediately if any Client You are
                        connected with through DogStays contacts you directly
                        for a business arrangement or pet care arrangement at
                        any point in the future.
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="alert-msg">
                <li>
                  4. Additional Expenses
                  <ul className="alert-msg">
                    <li>
                      <li>
                        4.1. If additional supplies need to be purchased during a
                        Booking, You agree to limit this expenditure to euro 50.
                        In case an additional amount is required to be spent,
                        You commit to contacting the Client before proceeding.
                      </li>
                      <li>
                        4.2. You agree to retain all relevant receipts in such a
                        situation and understand that the Client is only
                        required to reimburse expenses supported by valid
                        receipts.
                      </li>
                      <li>
                        4.3. In the case of an emergency You commit to using your
                        best judgement to care for the Client’s pet and to do
                        everything possible to ensure the health, safety and
                        security of this pet. You also commit to contacting the
                        Client and DogStays and informing them of the situation
                        immediately.{" "}
                      </li>
                      <li>
                        4.4. In an emergency situation, You agree to spend up to the
                        maximum amount indicated by the Client in the DogStays
                        Information Form. You understand that the Client is not
                        obliged to reimburse more than this amount in case You
                        spend it.{" "}
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="alert-msg">
                <li>
                  5. Liability
                  <ul className="alert-msg">
                    <li>
                      <li>
                        5.1. You agree that You will do your best to care for any
                        pets left in your care through such an arrangement and
                        that You are solely responsible for the care and safety
                        of these pets. DogStays is in no way liable for the
                        safety of the pets involved and cannot be held
                        responsible for any harm to a pet’s well-being at any
                        time during the Booking.{" "}
                      </li>
                      <li>
                      5.2. In the case that You will be caring for the Client’s
                        pet(s) at the client’s own home during the Booking, You
                        acknowledge and accept that You alone will be
                        responsible for the safety and security of the Client’s
                        home and DogStays will not be liable for any loss or
                        damage arising directly or indirectly from the Booking.{" "}
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="alert-msg">
                <li>
                  6. Cancellation
                  <ul className="alert-msg">
                    <li>
                      <li>
                        6.1. You agree not to cancel the Booking once You have
                        confirmed the Booking to DogStays or the Client. You
                        also agree to not change dates and other details of the
                        Booking. In case of a cancellation even after
                        confirmation, You must show just cause and if DogStays
                        deems the cause insufficient, You may be removed from
                        DogStays’ list of approved service providers without any
                        notice.
                      </li>
                      <li>
                        6.2. DogStays may cancel any Booking made through its
                        referral at any point without explanation to You if it
                        deems this in the best interest of the Client and the
                        pet.{" "}
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="alert-msg">
                <li>
                  7. Termination
                  <ul className="alert-msg">
                    <li>
                      <li>
                        7.1. These terms and conditions are continuing and are valid
                        for all Bookings made through such a referral by
                        DogStays to the dog sitter.
                      </li>
                      <li>
                        7.2. This agreement cannot be terminated while a pet is in
                        your care, i.e.: during a Booking and subject,
                         any Booking made prior to the termination of this
                        agreement must be honoured by You.
                      </li>
                      <li>
                        7.3. This agreement will remain in effect until it is
                        terminated by either party by providing the other at
                        least thirty (30) days prior written notice, via email
                        or registered post.{" "}
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="alert-msg">
                <li>
                  8. Confidentiality of Information
                  <ul className="alert-msg">
                    <li>
                      <li>
                        8.1. You agree to treat as strictly confidential all
                        information communicated to You by DogStays in the
                        context of the execution of this agreement and in
                        particular, any information concerning the Client or
                        DogStays, the products and services that are the subject
                        of this agreement including but not limited to the
                        contract, the Booking processes, the DogStays
                        Information Form, any trade secrets and the sales
                        methods recommended by DogStays, and any unauthorised
                        disclosure to any person in any form or capacity is
                        therefore prohibited for the duration of this agreement
                        and without limitation of duration after its expiration,
                        provided that the aforementioned information has not,
                        otherwise than by default or contravention of this
                        clause by You, fallen into the public domain.
                      </li>
                      <li>
                        8.2. You agree to provide DogStays with the necessary
                        information and documentation that is needed to
                        facilitate this arrangement and any Booking in the
                        future. This includes but is not limited to your contact
                        details, address, photos of place of residence, copy of
                        identification card and certificate of residence.
                      </li>
                      <li>
                        8.3. DogStays agrees to keep this information shared by you
                        confidential and only share it with Clients and
                        prospective Clients to facilitate the initiation or
                        completion of a Booking.
                      </li>
                      <li>
                        8.4. You therefore accept that DogStays will share this
                        information with one or more persons as part of
                        facilitating the Booking and cannot be held responsible
                        for what this person might do with the information.
                      </li>
                      <li>
                        8.5. You understand that DogStays will retain the information
                        shared for a duration of this agreement or 1 year from
                        the date of termination of this agreement and in case
                        You need to modify this information, You need to inform
                        DogStays via e-mail at dogstays@gmail.com.
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="alert-msg">
                <li>
                  9. Assignment
                  <ul className="alert-msg">
                    <li>
                      <li>
                        9.1. This agreement cannot be assigned or transferred by
                        either of the parties without the express, prior and
                        written consent of the other party.
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="alert-msg">
                <li>
                  10. Governing Law
                  <ul className="alert-msg">
                    <li>
                      <li>
                        10.1. This agreement is subject to the laws and jurisdiction
                        of the Grand- Duchy of Luxembourg and any dispute will
                        be settled by the competent authority within the city of
                        Luxembourg.
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <div className="row">
                <div className="w-50  text-start">
                  <p className="alert-msg">
                    Accepted by: (Name, First Name):{" "}
                    <b>
                      {window.localStorage.getItem("userDataLName")}{" "}, {window.localStorage.getItem("userDataFName")}
                    </b>
                  </p>
                  {/* <p className="alert-msg">
                    Please Select ACCEPTED below to accept this agreement
                  </p> */}
                </div>
                <div className="w-50 text-center">
                  <p className="alert-msg">Place: Luxembourg </p>
                  <p className="alert-msg">
                    Date: {new Date().toLocaleDateString()}
                  </p>
                </div>
              </div>

              <div className="row justify-content-center">
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="acception_is_required">
                    Please Select ACCEPTED below to accept this agreement
                    </Label>
                    <Input
                      name="acception_is_required"
                      placeholder="Enter Detail"
                      value={isAgreementAccepted}
                      onChange={handelAgreementInput}
                      type="select"
                      className="form-control"
                      id="acception_is-required"
                    >
                      <option hidden>Select</option>
                      <option value="Yes">Accepted</option>
                    </Input>
                  </FormGroup>
                </Col>
              </div>

              <div className="col-12 text-center">
                <DynamicButton
                  createBtnText="Close"
                  clickFunction={() => {
                    setIsAgreementModal(false);
                    setValidateCheckAgreement(false)
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}

{isAllCheckAlertModal && (
        <div>
          <Modal
            size="lg"
            isOpen={isAllCheckAlertModal}
            centered
            backdrop="static"
          >
            <ModalBody>
              <h4 className="alert-msg text-center">
                To Continue
              </h4>
              <h6 className="alert-msg text-center">
                Please Accept Both Privacy Policy and Agreement
              </h6>
              <div className="col-12 text-center">
                <DynamicButton
                  createBtnText="Close"
                  clickFunction={() => {
                    setIsAllCheckAlertModal(false);
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
};

export default SitterKycForm;
